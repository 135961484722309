import axios from 'axios';
import { message } from 'antd';
import update from 'react-addons-update';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import constants from './actionConstants';
import { logActivity } from '../../ActivityLog/module';
import { IApplicationState } from '../../../store/reducers';
import { IContents } from '../../../typings';

export interface IContentsState {
  contents?: Array<IContents>;
  showEditModal: boolean;
  isLoading: boolean;
  scenariosServices?: {
    [key: string]: {
      scenarios: Array<string>;
      services: Array<string>;
    };
  };
}

type Action =
  | {
      type: 'GET_CONTENTS';
      payload: any;
    }
  | {
      type: 'UPDATE_CONTENTS';
      payload: string;
    }
  | {
      type: 'TOGGLE_EDIT_CONTENTS_MODAL';
      payload: string;
    }
  | {
      type: 'CONTENTS_LOADING';
      payload: string;
    };

const {
  GET_CONTENTS,
  UPDATE_CONTENTS,
  TOGGLE_EDIT_CONTENTS_MODAL,
  CONTENTS_LOADING,
  CREATE_CONTENTS,
  DELETE_CONTENTS,
  GET_ALL_SCENARIOS_SERVICES,
} = constants;

/* ACTIONS */

export function getContents() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-all-content`);
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_CONTENTS,
          payload: data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error('Error! Failed to get contents.');
      }
    } catch (err) {
      dispatch(toggleLoading(false));
    }
  };
}

export function getScenarioService() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-all-scenarios`);

      console.log(result.status);
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_ALL_SCENARIOS_SERVICES,
          payload: data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error('Error, Failed to get scenarios & services');
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function createContents(payload: IContents) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: 'post',
        url: `/api/v1/insert-content`,
        data: payload,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: CREATE_CONTENTS,
          payload: result.data,
        });
        // @ts-ignore
        // dispatch(getContents());
        dispatch(toggleLoading(false));
        message.success('Content text created successfully');
        // @ts-ignore
        dispatch(logActivity(`Created content text for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario} Language: ${payload.language} `));
        setTimeout(() => {
          window.location.href = `/contents/browse`;
        }, 1000);
      } else {
        dispatch(toggleLoading(false));
        message.error('Error, could not create campaigns!');
      }
    } catch (err) {
      dispatch(toggleLoading(false));
    }
  };
}

export function updateContents(payload: IContents) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: 'post',
        url: `/api/v1/update-content`,
        data: payload,
      });
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: UPDATE_CONTENTS,
          payload: data,
        });
        // @ts-ignore
        // dispatch(getContents());
        // dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success(`Content text updated!`);
        //@ts-ignore
        dispatch(logActivity(`Updated content text for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario} Language: ${payload.language} `));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        dispatch(toggleLoading(false));
        message.error('Error while updating contents!');
      }
    } catch (err) {
      dispatch(toggleLoading(false));
    }
  };
}

export function deleteContents(payload: IContents) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: 'post',
        url: `/api/v1/delete-content`,
        data: payload,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: DELETE_CONTENTS,
          payload: result.data,
        });
        // @ts-ignore
        dispatch(getContents());
        dispatch(toggleLoading(false));
        message.success('Content text deleted successfully');
        // @ts-ignore
        dispatch(logActivity(`Deleted content text for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario} Language: ${payload.language} `));
      } else {
        dispatch(toggleLoading(false));
        message.error('Error, could not delete contents!');
      }
    } catch (err) {
      dispatch(toggleLoading(false));
    }
  };
}

export function toggleEditModal(payload: boolean) {
  return {
    type: TOGGLE_EDIT_CONTENTS_MODAL,
    payload,
  };
}

export function toggleLoading(payload: boolean) {
  return {
    type: CONTENTS_LOADING,
    payload,
  };
}

/* ACTION HANDLERS */

function handleContents(state: IContentsState, action: Action) {
  return update(state, {
    contents: {
      $set: action.payload,
    },
  });
}

function handleGetAllScenariosServices(state: IContentsState, action: Action) {
  return update(state, {
    scenariosServices: {
      $set: action.payload,
    },
  });
}

function handleCreateContents(state: IContentsState, action: Action) {
  return update(state, {
    createdContents: {
      $set: action.payload,
    },
  });
}

function handleUpdateContents(state: IContentsState, action: Action) {
  return update(state, {
    contents: {
      $set: action.payload,
    },
  });
}

function handleToggleEditModal(state: IContentsState, action: Action) {
  return update(state, {
    showEditModal: {
      $set: action.payload,
    },
  });
}

function handleLoading(state: IContentsState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS: any = {
  GET_CONTENTS: handleContents,
  GET_ALL_SCENARIOS_SERVICES: handleGetAllScenariosServices,
  CREATE_CONTENTS: handleCreateContents,
  UPDATE_CONTENTS: handleUpdateContents,
  TOGGLE_EDIT_CONTENTS_MODAL: handleToggleEditModal,
  CONTENTS_LOADING: handleLoading,
};

const initialState = {
  showEditModal: false,
  isLoading: false,
};

export default function ContentssReducer(state = initialState, action: Action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
