// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from "axios";
import fileDownload from "js-file-download";
import enqueue from '../../../utils/fetchQueue'
import { logActivity } from "../../ActivityLog/module";
import { IApplicationState } from "../../../store/reducers";
import { ICampaign, IPublishedPage, ISource, ILegals } from "../../../typings";
import { url } from "inspector";
import { Alert, message } from "antd";

const {
  GET_LEGALS,
  UPDATE_LEGALS,
  TOGGLE_EDIT_LEGALS_MODAL,
  LEGALS_LOADING,
  CREATE_LEGALS,
  DELETE_LEGALS,
  GET_ALL_SCENARIOS_SERVICES
} = constants;

export interface ILegalsState {
  legals?: Array<ILegals>;
  showEditModal:boolean;
  isLoading:boolean;
  scenariosServices?:{
    [key:string]:{
      scenarios:Array<string>,
      services:Array<string>,
    }
  }
}

type Action = 
{
  type: "GET_LEGALS",
  payload:any
}
|
{
  type: "UPDATE_LEGALS",
  payload:string
}
|
{
  type: "TOGGLE_EDIT_LEGALS_MODAL",
  payload:string
}
|
{
  type: "LEGALS_LOADING",
  payload:string
}


export function getLegals(){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios.get(`/api/v1/get-all-legals`);
      
      console.log(result.status)
      if(result.status === 200){


      dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_LEGALS,
          payload:data
        }); 

      }else{

      dispatch(toggleLoading(false));
      message.error("Error, Failed to get legals")
      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}


export function getScenarioService(){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios.get(`/api/v1/get-all-scenarios`);
      
      console.log(result.status)
      if(result.status === 200){


      dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_ALL_SCENARIOS_SERVICES,
          payload:data
        }); 

      }else{

      dispatch(toggleLoading(false));
      message.error("Error, Failed to get scenarios & services")
      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}




export function toggleEditModal(payload:boolean){
  return {
    type:TOGGLE_EDIT_LEGALS_MODAL,
    payload
  }
}

export function updateLegals(payload:ILegals) {
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios({
        method:"post",
        url:`/api/v1/update-legals`,
        data:payload
      });
      if(result.status === 200){
        const data = result.data;
        dispatch({
          type: UPDATE_LEGALS,
          payload:data
        });
        //@ts-ignore
        //dispatch(getLegals());
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success(`Legal text updated!`);
        //@ts-ignore
        dispatch(logActivity(`Updated Legal text for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario} Language: ${payload.language} `))
        setTimeout(() => {
          window.location.reload();
        }, 1000);
       
      }else{
        dispatch(toggleLoading(false));
        message.error("Error while updating legals!")

      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}




export function createLegals(payload:ILegals) {
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios({
        method:"post",
        url:`/api/v1/add-legals`,
        data:payload
      });
      if(result.status === 200){

        dispatch(toggleLoading(false));
        dispatch({
          type: CREATE_LEGALS,
          payload:result.data
        });
        //@ts-ignore
        //dispatch(getLegals());
        dispatch(toggleLoading(false));
        message.success("Legal Text created successfully");
        //@ts-ignore
        dispatch(logActivity(`Created Legal text for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario} Language: ${payload.language} `))
        setTimeout(() => {
          window.location.href = `/legals/browse`;
        }, 1000);
      }else{
        dispatch(toggleLoading(false));
        message.error("Error, could not create campaigns!")

      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}

export function deleteLegals(payload:ILegals) {
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios({
        method:"post",
        url:`/api/v1/delete-legal`,
        data:payload
      });
      if(result.status === 200){

        dispatch(toggleLoading(false));
        dispatch({
          type: DELETE_LEGALS,
          payload:result.data
        });
        //@ts-ignore
        dispatch(getLegals());
        dispatch(toggleLoading(false));
        message.success("Legal Text deleted successfully");
        //@ts-ignore
        dispatch(logActivity(`Deleted Legal text for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario} Language: ${payload.language} `))
      }else{
        dispatch(toggleLoading(false));
        message.error("Error, could not delete legals!")

      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}


//TOGGLE_LOGIN
export function toggleLoading(payload:boolean){
  return{
    type:LEGALS_LOADING,
    payload
  }
}


function handleLegals(state:ILegalsState, action:Action) {
  return update(state, {
    legals:{
      $set:action.payload
    }
  })
}

function handleToggleEditModal(state:ILegalsState, action:Action) {
  return update(state, {
    showEditModal:{
      $set:action.payload
    }
  })
}

//LOGIN_LOADING
function handleLoading(state:ILegalsState, action:Action) {
  return update(state, {
    isLoading:{
      $set:action.payload
    }
  })
}




function handleCreateLegals(state:ILegalsState, action:Action) {
  return update(state, {
    createdLegals:{
      $set:action.payload
    }
  })
}

function handleUpdateLegals(state:ILegalsState, action:Action) {
  return update(state, {
    legals:{
      $set:action.payload
    }
  })
}

function handleGetAllScenariosServices(state:ILegalsState, action:Action) {
  return update(state, {
    scenariosServices:{
      $set:action.payload
    }
  })
}






const ACTION_HANDLERS = {
  GET_LEGALS:handleLegals,
  TOGGLE_EDIT_LEGALS_MODAL:handleToggleEditModal,
  LEGALS_LOADING:handleLoading,
  CREATE_LEGALS:handleCreateLegals,
  UPDATE_LEGALS:handleUpdateLegals,
  GET_ALL_SCENARIOS_SERVICES:handleGetAllScenariosServices
};

const initialState = {
  showEditModal:false,
  isLoading:false
};

export default function LegalsReducer(state = initialState, action:Action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
