export default {
  GET_HANDLE_CONFIG:"GET_HANDLE_CONFIG",
  CONFIGS_LOADING:"CONFIGS_LOADING",
  CREATE_STRATEGY:"CREATE_STRATEGY",
  GET_COUNTRIES_OPERATORS:"GET_COUNTRIES_OPERATORS",
  GET_STRATEGIES:"GET_STRATEGIES",
  CREATE_SCENARIO:"CREATE_SCENARIO",
  DELETE_STRATEGY:"DELETE_STRATEGY"

  
};