// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { IApplicationState } from "../../../store/reducers";
import { message } from "antd";
import { ICampaign, IPublishedPage, ISplitTraffic } from "../../../typings";
import * as R from "ramda";
import { logActivity } from "../../ActivityLog/module";

const {
  ABTESTS_LOADING,
  GET_SPLIT_TRAFFIC_TESTS,
  CREATE_SPLIT_TRAFFIC_TEST,
  LOG_ACTIVITY,
  GET_PAGES,
  STOP_SPLIT_TRAFFIC_TEST,
  GET_SPLIT_TRAFFIC_REPORT,
  GET_SPLIT_TRAFFIC_TESTS_EXPIRED,
  GET_SPLIT_TRAFFIC_TEST,
  EDIT_SPLIT_TRAFFIC_TEST,
} = constants;

export interface ISplitTrafficReducerState {
  splitTrafficTests: Array<ISplitTraffic>;
  splitTrafficTestsExpired: Array<ISplitTraffic>;
  splitTest: ISplitTraffic;
  isLoading: boolean;
  pages: Array<IPublishedPage>;
  countries: Array<string>;
  splitTrafficTestReport: Array<any>;
  // isAbTestNameValid: boolean;
}

type Action =
  | {
      type: "GET_SPLIT_TRAFFIC_TESTS";
      payload: any;
    }
  | {
      type: "CREATE_SPLIT_TRAFFIC_TEST";
      payload: any;
    }
  | {
      type: "GET_PAGES";
      payload: any;
    }
  | {
      type: "STOP_SPLIT_TRAFFIC_TEST";
      payload: any;
    }
  | {
      type: "GET_SPLIT_TRAFFIC_REPORT";
      payload: any;
    };

const getUniqueCountries = async (pages: Array<IPublishedPage>) => {
  const u = R.pipe(
    R.map((o: any) => o.country as string),
    R.uniq,
    R.sortBy((x) => x)
  )(pages);
  return u;
};
//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: ABTESTS_LOADING,
    payload,
  };
}

export function getSplitTrafficTests() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(
        `/api/v1/get-split-traffic-tests?type=active`
      );
      if (result.status === 200 && result.data) {
        dispatch(toggleLoading(false));
        dispatch({
          type: GET_SPLIT_TRAFFIC_TESTS,
          payload: result.data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get activity log");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getSplitTrafficTestsExpired() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(
        `/api/v1/get-split-traffic-tests?type=expired`
      );
      if (result.status === 200 && result.data) {
        dispatch(toggleLoading(false));
        dispatch({
          type: GET_SPLIT_TRAFFIC_TESTS_EXPIRED,
          payload: result.data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get activity log");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getPages() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(`/api/v1/get-released-pages`);
    try {
      dispatch(toggleLoading(true));
      if (result.status === 200) {
        const data = result.data;
        const countries = await getUniqueCountries(data);
        dispatch({
          type: GET_PAGES,
          payload: { countries, data },
        });
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))

        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getAbTestReport({
  country,
  owl_name,
  start_date,
}: {
  country: string;
  owl_name: string;
  start_date: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(
      `/api/v1/ab-test-report?country=${country}&owl_name=${owl_name}&start_date=${start_date}`
    );
    try {
      dispatch(toggleLoading(true));
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: GET_SPLIT_TRAFFIC_REPORT,
          payload: data,
        });
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))

        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getSplitTest({ id }: { id: number }) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(
      `/api/v1/get-single-split-traffic-test?id=${id}`
    );
    try {
      dispatch(toggleLoading(true));
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: GET_SPLIT_TRAFFIC_TEST,
          payload: data,
        });
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))

        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function createSplitTest(payload: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const currentUser = store().login.currentUser;
    try {
      if (currentUser) {
        dispatch(toggleLoading(true));
        const result = await axios({
          method: "post",
          url: `/api/v1/create-split-traffic`,
          data: payload,
        });
        if (result.status === 200) {
          dispatch({
            type: CREATE_SPLIT_TRAFFIC_TEST,
            payload: result.data,
          });

          dispatch(toggleLoading(false));
          //@ts-ignore
          dispatch(
            //@ts-ignore

            logActivity(
              `Created Split Test Test <a href="https://panel.ouisys.com/split-traffic/history?split_traffic_id=${result.data.split_traffic_id}" target="_blank" rel="noopener noreferrer">Name: ${payload.split_traffic_test_name}</a>`
            )
          );
          message.success("Test Started Successfully!");

          //@ts-ignore
          dispatch(getSplitTrafficTests());
          //@ts-ignore
          dispatch(getSplitTrafficTestsExpired());
          setTimeout(() => {
            window.location.href = "/split-traffic/history";
          }, 2000);
        } else {
          message.error("Error, Failed to create abtest");
        }
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function editSplitTest(payload: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const currentUser = store().login.currentUser;
    try {
      if (currentUser) {
        dispatch(toggleLoading(true));
        const result = await axios({
          method: "patch",
          url: `/api/v1/edit-split-traffic`,
          data: payload,
        });
        if (result.status === 200) {
          dispatch({
            type: EDIT_SPLIT_TRAFFIC_TEST,
            payload: result.data,
          });

          dispatch(toggleLoading(false));
          //@ts-ignore
          dispatch(
            //@ts-ignore

            logActivity(
              `Edited Split Test  <a href="https://panel.ouisys.com/split-traffic/history?split_traffic_id=${result.data.split_traffic_id}" target="_blank" rel="noopener noreferrer">Name: ${payload.split_traffic_test_name}</a>`
            )
          );
          message.success("Test Edited Successfully!");

          //@ts-ignore
          dispatch(getSplitTrafficTests());
          //@ts-ignore
          dispatch(getSplitTrafficTestsExpired());
          setTimeout(() => {
            window.location.href = "/split-traffic/history";
          }, 2000);
        } else {
          message.error("Error, Failed to edit test");
        }
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function stopSplitTrafficTest({
  id,
  split_traffic_test_name,
}: {
  id: number;
  split_traffic_test_name: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const currentUser = store().login.currentUser;
    try {
      dispatch(toggleLoading(true));
      const result = await axios({
        method: "post",
        url: `/api/v1/stop-split-traffic-test`,
        data: {
          id,
        },
      });
      if (result.status === 200) {
        dispatch({
          type: STOP_SPLIT_TRAFFIC_TEST,
          payload: result.data,
        });

        dispatch(toggleLoading(false));
        message.success("Split Traffic Test Stopped Successfully!");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Split Traffic Test <a href="https://panel.ouisys.com/split-traffic/history?split_traffic_test_name=${split_traffic_test_name}" target="_blank" rel="noopener noreferrer">Name: ${split_traffic_test_name} </a>`
          )
        );
        //@ts-ignore
        dispatch(getABTests());
        //@ts-ignore
        dispatch(getABTestsExpired());
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to create abtest");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

function handleGetABTests(state: ISplitTrafficReducerState, action: Action) {
  return update(state, {
    splitTrafficTests: {
      $set: action.payload,
    },
  });
}

function handleLoading(state: ISplitTrafficReducerState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

function handleLogActivity(state: ISplitTrafficReducerState, action: Action) {
  return update(state, {
    loggedActivity: {
      $set: action.payload,
    },
  });
}

function handleGetPages(state: ISplitTrafficReducerState, action: Action) {
  return update(state, {
    pages: {
      $set: action.payload.data,
    },
    countries: {
      $set: action.payload.countries,
    },
  });
}

function handleGetAbtestReport(
  state: ISplitTrafficReducerState,
  action: Action
) {
  return update(state, {
    abTestReport: {
      $set: action.payload,
    },
  });
}

function handleGetSplitTest(state: ISplitTrafficReducerState, action: Action) {
  return update(state, {
    splitTest: {
      $set: action.payload,
    },
  });
}
function handleGetExpiredTests(
  state: ISplitTrafficReducerState,
  action: Action
) {
  return update(state, {
    splitTrafficTestsExpired: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS = {
  ABTESTS_LOADING: handleLoading,
  GET_SPLIT_TRAFFIC_TESTS: handleGetABTests,
  LOG_ACTIVITY: handleLogActivity,
  GET_PAGES: handleGetPages,
  GET_SPLIT_TRAFFIC_REPORT: handleGetAbtestReport,
  GET_SPLIT_TRAFFIC_TESTS_EXPIRED: handleGetExpiredTests,
  GET_SPLIT_TRAFFIC_TEST: handleGetSplitTest,
};

const initialState = {
  isLoading: false,
};

export default function ABTestsReducer(state = initialState, action: any) {
  //@ts-ignore
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
