// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import fileDownload from "js-file-download";
import enqueue from "../../../utils/fetchQueue";
import queryString from "query-string";
import { logActivity } from "../../ActivityLog/module";

import { IApplicationState } from "../../../store/reducers";
import {
  ICampaign,
  IPublishedPage,
  ISource,
  IUnpublishedPage,
  IPagesStates,
} from "../../../typings";
import { url } from "inspector";
import { Alert, message } from "antd";
import { async } from "q";
import { ISelectedAffIds } from "../../Campaigns/components/CreateCampaign";
import cleanObj from "../../../utils/cleanObj";

const {
  GET_PUBLISHED_PAGES,
  PAGES_LOADING,
  EXPORT_EXCEL,
  GET_UNPUBLISHED_PAGES,
  PUBLISH_PAGE,
  PUBLISH_CREATE_CAMPAIGN,
  RESET_PUBLISH_CAMPAIGN,
  HIDE_PUBLISHED_PAGE,
  GENERATE_LEGALS,
  GET_PAGE_STATS,
  MARK_AS_TESTED,
  SET_TAGS,
} = constants;

export interface IPagesState {
  publishedPages?: Array<IPublishedPage>;
  publishCampaign?: ICampaign;
  unpublishedPages?: Array<IUnpublishedPage>;
  publishedPage?: IPublishedPage;
  isLoading: boolean;
  pagesStats: Array<IPagesStates>;
}

export interface IPublishpageRequest {
  html_url: string;
  page_upload_id: number;
  username: string;
  page: string;
  country: string;
  scenario: string;
  strategy: string;
  scenarios_config: string;
  comments: string;
  affid: string;
}

export interface IHidePublishedPageRequest {
  page: string;
  country: string;
  scenario: string;
  strategy: string;
  scenarios_config: string;
  hide: boolean;
}

export interface IMarkAsTestedRequest {
  id: number;
  test_remarks: string;
  is_tested: boolean;
  tested_by: string;
}

export interface ITagsRequest {
  id: number;
  tags: string;
  vertical: string;
}

type Action =
  | {
      type: "GET_PUBLISHED_PAGES";
      payload: any;
    }
  | {
      type: "PAGES_LOADING";
      payload: boolean;
    }
  | {
      type: "EXPORT_EXCEL";
      payload: any;
    }
  | {
      type: "GET_UNPUBLISHED_PAGES";
      payload: any;
    }
  | {
      type: "PUBLISH_PAGE";
      payload: IPublishpageRequest;
    }
  | {
      type: "PUBLISH_CREATE_CAMPAIGN";
      payload: any;
    }
  | {
      type: "RESET_PUBLISH_CAMPAIGN";
      payload: any;
    }
  | {
      type: "HIDE_PUBLISHED_PAGE";
      payload: any;
    }
  | {
      type: "GET_PAGE_STATS";
      payload: any;
    };

export function getPageStats() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    try {
      enqueue(() =>
        fetch(
          `https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rQ6KiZu/execute/7500/query`
        )
          .then((x) => x.json())
          .then((data) =>
            dispatch({
              type: GET_PAGE_STATS,
              payload: data,
            })
          )
          .catch((_error) => message.error("Error, Failed to get stats"))
      );
    } catch (err) {
      // message.error(JSON.stringify(err))
    }
  };
}

export function getPublishedPages(params?: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const Qparams = !params
      ? ""
      : "?" +
        Object.keys(params)
          .map((k) => `${k}=${params[k]}`)
          .join("&");

    const result = await axios.get(`/api/v1/get-released-pages${Qparams}`);
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: GET_PUBLISHED_PAGES,
          payload: data,
        });
        dispatch(toggleLoading(false));
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function publishPage(payload: IPublishpageRequest) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      url: `/api/v1/publish_page`,
      method: "POST",
      data: payload,
    });
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: PUBLISH_PAGE,
          payload: data,
        });
        dispatch(toggleLoading(false));
        //@ts-ignore
        dispatch(createSingleCampaign(payload));
        //@ts-ignore
        dispatch(getUnpublishedPages());
        message.success("Page published successfully!");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Published page <a href="https://panel.ouisys.com/pages/published?${queryString.stringify(
              cleanObj({
                page: payload.page,
                country: payload.country,
                scenario: payload.scenario,
                strategy: payload.strategy,
                scenarios_config: payload.scenarios_config,
              })
            )}">${JSON.stringify(
              cleanObj({
                page: payload.page,
                country: payload.country,
                scenario: payload.scenario,
                strategy: payload.strategy,
                scenarios_config: payload.scenarios_config,
              })
            )}</a>`
          )
        );
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not publish page");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function createSingleCampaign(payload: IPublishpageRequest) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      url: `/api/v1/create_campaign`,
      method: "POST",
      data: payload,
    });
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: PUBLISH_CREATE_CAMPAIGN,
          payload: data,
        });
        dispatch(toggleLoading(false));
        message.success("Page published successfully!");
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not publish page");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function markAsTested(payload: IMarkAsTestedRequest) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      url: `/api/v1/mark-as-tested`,
      method: "POST",
      data: payload,
    });
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: MARK_AS_TESTED,
          payload: data,
        });
        dispatch(toggleLoading(false));
        message.success("Marked as tested successfully!");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Marked page <a href="https://panel.ouisys.com/pages/published?id=${payload.id}>${payload.id} test</a>`
          )
        );
        //@ts-ignore
        dispatch(getPublishedPages());
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not publish page");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function setPageTags(payload: ITagsRequest) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      url: `/api/v1/set-tags`,
      method: "PATCH",
      data: payload,
    });
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: SET_TAGS,
          payload: data,
        });
        message.success("Tags set successfully!");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Added tags to <a href="https://panel.ouisys.com/pages/published?id=${payload.id}>${payload.id}</a>`
          )
        );

        dispatch(toggleLoading(false));
        //@ts-ignore
        dispatch(getPublishedPages());
        // //@ts-ignore
        // dispatch(getUnpublishedPages());
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not publish page");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getUnpublishedPages() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios.get(`/api/v1/get-uploaded-pages`);
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: "GET_UNPUBLISHED_PAGES",
          payload: data,
        });
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function hidePublishedPage(payload: IHidePublishedPageRequest) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios({
      url: `/api/v1/hide_published_page`,
      method: "POST",
      data: payload,
    });
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: HIDE_PUBLISHED_PAGE,
          payload: data,
        });
        message.success("Page hidden");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Hid page ${JSON.stringify(
              cleanObj({
                page: payload.page,
                country: payload.country,
                scenario: payload.scenario,
                strategy: payload.strategy,
                scenarios_config: payload.scenarios_config,
              })
            )}</a>`
          )
        );
        //@ts-ignore
        dispatch(getPublishedPages());
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not hide  page");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function exportToExcel(payload: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      method: "post",
      url: `/api/v1/excel`,
      data: payload,
    });
    if (result.status === 200) {
      const data = result.data;
      dispatch({
        type: EXPORT_EXCEL,
        payload: data,
      });

      const decodeBase64 = (str: any) => {
        try {
          return window.atob(str);
        } catch (e) {
          return str;
        }
      };

      const decoded = decodeBase64(data);
      const buf = new ArrayBuffer(decoded.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== decoded.length; ++i) {
        view[i] = decoded.charCodeAt(i) & 0xff;
      }

      const blob = new Blob([buf], {
        type: "application/octet-stream",
      });
      //window.location.href = data
      // fileDownload(blob, 'data.csv');
      //dispatch(toggleEditModal(false));
      dispatch(toggleLoading(false));
    } else {
      dispatch(toggleLoading(false));
      message.error("Error while exporting!");
    }
  };
}

export function takeScreenShot(payload: {
  page: string;
  country: string;
  scenario: string;
  strategy: string;
  scenarios_config: string;
}) {
  const { page, country, scenario, strategy, scenarios_config } = payload;

  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(
      `/api/v1/take_screenshot?page=${page}&country=${country}&scenario=${scenario}&strategy=${strategy}&scenarios_config=${scenarios_config}&environment=production`
    );
    if (result.status === 200) {
      const data = result.data;
      dispatch({
        type: "ujujujuj",
        payload: data,
      });
    }
  };
}

export function generateLegals(payload: {
  page: string;
  country: string;
  scenario: string;
  strategy: string;
  scenarios_config: string;
}) {
  const { page, country, scenario, strategy, scenarios_config } = payload;

  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const currentUser = store().login.currentUser;
    const result = await axios({
      method: "post",
      url: `/api/v1/generate-legals`,
      data: {
        username: currentUser ? currentUser.username : "ouisys-panel",
        ...payload,
      },
      timeout: 300000,
    });
    if (!!result && result.status === 200) {
      const data = result.data;
      dispatch({
        type: GENERATE_LEGALS,
        payload: data,
      });
      message.success(
        require("html-react-parser")(
          `
          <h6>Legal text generated successfully. Click the link below to test and publish the page!</h6>
          <a target="_blank" rel="noopener noreferrer"  href="https://panel.ouisys.com/pages/unpublished?${queryString.stringify(
            cleanObj({
              page: payload.page,
              country: payload.country,
              scenario: payload.scenario,
              strategy: payload.strategy,
              scenarios_config: payload.scenarios_config,
            })
          )}">${payload.page}</a>`
        ),
        10000
      );

      dispatch(
        //@ts-ignore

        logActivity(
          `Generated Legal text for page <a href="https://panel.ouisys.com/pages/unpublished?${queryString.stringify(
            cleanObj({
              page: payload.page,
              country: payload.country,
              scenario: payload.scenario,
              strategy: payload.strategy,
              scenarios_config: payload.scenarios_config,
            })
          )}">${JSON.stringify(
            cleanObj({
              page: payload.page,
              country: payload.country,
              scenario: payload.scenario,
              strategy: payload.strategy,
              scenarios_config: payload.scenarios_config,
            })
          )}</a>`
        )
      );
    } else {
    }
    dispatch(toggleLoading(false));
  };
}

//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: PAGES_LOADING,
    payload,
  };
}

export function resetPublishCampaign() {
  return {
    type: RESET_PUBLISH_CAMPAIGN,
  };
}

//LOGIN_LOADING
function handleLoading(state: IPagesState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

function handleGetPublishedPages(state: IPagesState, action: Action) {
  //if(!state.publishedPages){
  return update(state, {
    publishedPages: {
      $set: action.payload,
    },
  });
  //}else{
  // return update(state, {
  //   publishedPages:{
  //     $push:action.payload
  //   }
  // })
  ///}
}

function handleExportToExcel(state: IPagesState, action: Action) {
  return update(state, {
    exportToExcel: {
      $set: action.payload,
    },
  });
}

function handleGetUnPublishedPages(state: IPagesState, action: Action) {
  return update(state, {
    unpublishedPages: {
      $set: action.payload,
    },
  });
}

function handlePublishPage(state: IPagesState, action: Action) {
  return update(state, {
    publishedPage: {
      $set: action.payload,
    },
  });
}

function handlePublishCreateSingleCampaign(state: IPagesState, action: Action) {
  return update(state, {
    publishCampaign: {
      $set: action.payload,
    },
  });
}

function handleResetPublishCampaign(state: IPagesState, action: Action) {
  return update(state, {
    publishCampaign: {
      $set: undefined,
    },
  });
}

function handleHidePublishedPage(state: IPagesState, action: Action) {
  return update(state, {
    hiddenPage: {
      $set: undefined,
    },
  });
}

function handleGetPageStats(state: IPagesState, action: Action) {
  return update(state, {
    pagesStats: {
      $set: action.payload,
    },
  });
}

function handleMarkAsTested(state: IPagesState, action: Action) {
  return update(state, {
    NNN: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS = {
  PAGES_LOADING: handleLoading,
  GET_PUBLISHED_PAGES: handleGetPublishedPages,
  EXPORT_EXCEL: handleExportToExcel,
  GET_UNPUBLISHED_PAGES: handleGetUnPublishedPages,
  PUBLISH_PAGE: handlePublishPage,
  PUBLISH_CREATE_CAMPAIGN: handlePublishCreateSingleCampaign,
  RESET_PUBLISH_CAMPAIGN: handleResetPublishCampaign,
  HIDE_PUBLISHED_PAGE: handleHidePublishedPage,
  GET_PAGE_STATS: handleGetPageStats,
  MARK_AS_TESTED: handleMarkAsTested,
};

const initialState = {
  isLoading: false,
  pagesStats: [{}],
};

export default function PagesReducer(state = initialState, action: Action) {
  const handler = ACTION_HANDLERS[action.type];
  //@ts-ignore
  return handler ? handler(state, action) : state;
}
