import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "../components/AuthenticatedRoute";
import UnauthenticatedRoute from "../components/UnauthenticatedRoute";
import Loadable from "react-loadable";
import AsyncLoader from "../components/AsyncLoader";

// import NotFound from "./NotFound";

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ "./Home"),
  loading: AsyncLoader,
  modules: ["home"]
});

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Login"),
  loading: AsyncLoader,
  modules: ["login"]
});

const CampaignsBrowse = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Campaigns/components/Browse"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});
const CreateCampaigns = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Campaigns/components/Create"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});

const Unpublished = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Pages/Unpublished"),
  loading: AsyncLoader,
  modules: ["unpublished"]
});

const Published = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Pages/Published"),
  loading: AsyncLoader,
  modules: ["Published"]
});

const LegalsBrowse = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Legals/Browse"),
  loading: AsyncLoader,
  modules: ["legals"]
});

const LegalsCreate = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Legals/Create"),
  loading: AsyncLoader,
  modules: ["legals"]
});

const Scenarios = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Configs/Scenarios"),
  loading: AsyncLoader,
  modules: ["scenarios"]
});

const ScenariosBrowse = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Configs/Scenarios/Browse"),
  loading: AsyncLoader,
  modules: ["scenarios"]
});

const ScenariosCreate = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Configs/Scenarios/Create"),
  loading: AsyncLoader,
  modules: ["scenarios"]
});

const StrategiesCreate = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Configs/Strategies/Create"),
  loading: AsyncLoader,
  modules: ["strategies"]
});

const StrategiesBrowse = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Configs/Strategies/Browse"),
  loading: AsyncLoader,
  modules: ["strategies"]
});

const ActivityLog = Loadable({
  loader: () => import(/* webpackChunkName: "activityLog" */ "./ActivityLog"),
  loading: AsyncLoader,
  modules: ["activityLog"]
});

// const Logout = Loadable({
//   loader: () => import(/* webpackChunkName: "logout" */ "./Logout"),
//   loading: () => null,
//   modules: ["logout"]
// });

const ABTestsHistory = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./ABTests/components/History"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});
const CreateABTest = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./ABTests/components/Create"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});

const SplitTrafficHistory = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./SplitTraffic/components/History"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});
const CreateSplitTraffic = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./SplitTraffic/components/Create"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});
const EditSplitTraffic = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./SplitTraffic/components/Create"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});
const CreateCampaignsByAff = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Campaigns/components/CreateCampaignsByAff"),
  loading: AsyncLoader,
  modules: ["campaigns"]
});

const HotPages = Loadable({
  loader: () => import(/* webpackChunkName: "activityLog" */ "./HotPages"),
  loading: AsyncLoader,
  modules: ["hot-pages"]
});

const ContentsBrowse = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Contents/Browse"),
  loading: AsyncLoader,
  modules: ["contents"]
});

const ContentsCreate = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./Contents/Create"),
  loading: AsyncLoader,
  modules: ["contents"]
});

const AnalyseDomains = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./AnalyseDomains"),
  loading: AsyncLoader,
  modules: ["pages"]
});

const AntiFraudRulesBrowse = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./AntiFraudRules/Browse"),
  loading: AsyncLoader,
  modules: ["antiFraudRules"]
});

const AntiFraudRulesCreate = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./AntiFraudRules/Create"),
  loading: AsyncLoader,
  modules: ["antiFraudRules"]
});

export default () => (
  <Switch>

    <AuthenticatedRoute 
      exact path="/"
      //@ts-ignore
      component={Home}
    />
    <AuthenticatedRoute 
      exact path="/campaigns/browse"
      //@ts-ignore
      component={CampaignsBrowse}
    />
    <AuthenticatedRoute 
      exact path="/campaigns/create"
      //@ts-ignore
      component={CreateCampaigns}
    />
    <AuthenticatedRoute 
      exact path="/pages/unpublished"
      //@ts-ignore
      component={Unpublished}
    />
    <AuthenticatedRoute 
      exact path="/pages/published"
      //@ts-ignore
      component={Published}
    />
    <AuthenticatedRoute 
      exact path="/configs/scenarios/create"
      //@ts-ignore
      component={ScenariosCreate}
    />
    <AuthenticatedRoute 
      exact path="/configs/scenarios/browse"
      //@ts-ignore
      component={ScenariosBrowse}
    />
    <AuthenticatedRoute 
      exact path="/configs/strategies/create"
      //@ts-ignore
      component={StrategiesCreate}
    />
    <AuthenticatedRoute 
      exact path="/configs/strategies/browse"
      //@ts-ignore
      component={StrategiesBrowse}
    />
    <Route 
      exact path="/configs/strategies/manage/"
      //@ts-ignore
      component={StrategiesBrowse}
    />
    <AuthenticatedRoute 
      exact path="/legals/browse"
      //@ts-ignore
      component={LegalsBrowse}
    />
    <AuthenticatedRoute 
      exact path="/legals/create"
      //@ts-ignore
      component={LegalsCreate}
    />
    <UnauthenticatedRoute
      exact path="/login"
      //@ts-ignore
      component={Login}
    />
    <AuthenticatedRoute 
      exact path="/activity-log"
      //@ts-ignore
      component={ActivityLog}
    />
    <AuthenticatedRoute 
      exact path="/hot-pages"
      //@ts-ignore
      component={HotPages}
    />
    <AuthenticatedRoute 
      exact path="/abtests/history"
      //@ts-ignore
      component={ABTestsHistory}
    />
    <AuthenticatedRoute 
      exact path="/split-traffic/create"
      //@ts-ignore
      component={CreateSplitTraffic}
    />
    <AuthenticatedRoute 
      exact path="/split-traffic/edit/:id"
      key="edit"
      //@ts-ignore
      component={CreateSplitTraffic}
    />
    <AuthenticatedRoute 
      exact path="/split-traffic/history"
      //@ts-ignore
      component={SplitTrafficHistory}
    />
    <AuthenticatedRoute 
      exact path="/abtests/create"
      //@ts-ignore
      component={CreateABTest}
    />
    <AuthenticatedRoute 
      exact path="/campaigns/create-by-affiliate"
      //@ts-ignore
      component={CreateCampaignsByAff}
    />
    <AuthenticatedRoute 
      exact path="/contents/browse"
      //@ts-ignore
      component={ContentsBrowse}
    />
    <AuthenticatedRoute 
      exact path="/contents/create"
      //@ts-ignore
      component={ContentsCreate}
    />
    <AuthenticatedRoute 
      exact path="/anti-fraud-rules/browse"
      //@ts-ignore
      component={AntiFraudRulesBrowse}
    />
    <AuthenticatedRoute 
      exact path="/anti-fraud-rules/create"
      //@ts-ignore
      component={AntiFraudRulesCreate}
    />
    <AuthenticatedRoute 
      exact path="/analyse-domains"
      //@ts-ignore
      component={AnalyseDomains}
    />

    {/* <AuthenticatedRoute exact path="/logout" component={Logout} /> */}
    {/* <AuthenticatedRoute exact path="/dashboard" component={Dashboard} /> */}
    {/* <Route component={NotFound} /> */}

  </Switch>
);
