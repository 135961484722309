// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from "axios";
import { IApplicationState } from "../../../store/reducers";
import { message } from "antd";
import { ICampaign, IActivityLog, IGitCommitHistory } from "../../../typings";

const {
  ACTIVITY_LOG_LOADING,
  GET_ACTIVITY_LOG,
  LOG_ACTIVITY,
  GET_GIT_COMMIT_HISTORY
} = constants;



export interface IActivityLogReducerState {
  activityLog?:Array<IActivityLog>;
  loggedActivity?:IActivityLog;
  gitHistory?:IGitCommitHistory;
  isLoading:boolean;

}

type Action = 
{
  type: "GET_ACTIVITY_LOG",
  payload:any
}
|
{
  type: "LOG_ACTIVITY",
  payload:string
}

//TOGGLE_LOGIN
export function toggleLoading(payload:boolean){
  return{
    type:ACTIVITY_LOG_LOADING,
    payload
  }
}

export function getActivityLog(){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios.get(`/api/v1/get-activity-log`);
      if(result.status === 200 && result.data){


      dispatch(toggleLoading(false));
        dispatch({
          type: GET_ACTIVITY_LOG,
          payload:result.data
        }); 
      }else{
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get activity log")
      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}

export function getGitCommitHistory(page:string){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    dispatch(toggleLoading(true));
    try{
      const result = await axios.get(`/api/v1/get-commits/${encodeURIComponent(`ouisys/landing-pages/${page}`)}`);
      if(result.status === 200 && result.data){


      dispatch(toggleLoading(false));
        dispatch({
          type: GET_GIT_COMMIT_HISTORY,
          payload:{
            [page]:result.data
          }
        }); 
      }else{
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get activity log")
      }
    }catch(err){
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}

export function logActivity(payload:string){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{

    const currentUser = store().login.currentUser
    try{
      if(currentUser){
        const result = await axios({
          method:"post",
          url:`/api/v1/log-activity`,
          data:{
            username:currentUser.username,
            avatar:currentUser.avatar,
            message:payload
          }
        });
        if(result.status === 200){
          dispatch({
            type: LOG_ACTIVITY,
            payload:result.data
          }); 
        }else{
          message.error("Error, Failed to log activity")
        }
      }
    }catch(err){
      // message.error(JSON.stringify(err))
    }
  }
}



function handleGetActivityLog(state:IActivityLogReducerState, action:Action) {
  return update(state, {
    activityLog:{
      $set:action.payload
    }
  })
}

function handleLoading(state:IActivityLogReducerState, action:Action) {
  return update(state, {
    isLoading:{
      $set:action.payload
    }
  })
}

function handleLogActivity(state:IActivityLogReducerState, action:Action) {
  return update(state, {
    loggedActivity:{
      $set:action.payload
    }
  })
}

function handleGitCommitHistory(state:IActivityLogReducerState, action:Action) {
  return update(state, {
    gitHistory:{
      $set:action.payload
    }
  })
}

const ACTION_HANDLERS = {
  ACTIVITY_LOG_LOADING:handleLoading,
  GET_ACTIVITY_LOG:handleGetActivityLog,
  LOG_ACTIVITY:handleLogActivity,
  GET_GIT_COMMIT_HISTORY:handleGitCommitHistory
};

const initialState = {
  isLoading:false
};

export default function ActivityLogReducer(state = initialState, action:any) {
  //@ts-ignore
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
