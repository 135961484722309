// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from "axios";
import fileDownload from "js-file-download";
import enqueue from '../../../utils/fetchQueue'
import cleanObj from '../../../utils/cleanObj'
import queryString from "query-string";

import { IApplicationState } from "../../../store/reducers";
import { ICampaign, IPublishedPage, ISource, IHotPage, IMarkHotPageRequest } from "../../../typings";
import { url } from "inspector";
import { Alert, message } from "antd";
import { logActivity } from "../../ActivityLog/module";

const {
  GET_HOT_PAGES,
  HOT_PAGES_LOADING,
  MARK_PAGE_AS_HOT
} = constants;

export interface IHotPagesState {
  hotPages?: Array<IHotPage>;
  isLoading:boolean;
}


type Action = 
{
  type: "GET_HOT_PAGES",
  payload:any
}
|
{
  type: "HOT_PAGES_LOADING",
  payload:boolean
}
|
{
  type:"MARK_PAGE_AS_HOT",
  payload:any
}




export function getHotPages(){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    const result = await axios.get(`/api/v1/get-hot-pages`);
    try{

      if(result.status === 200){
        const data = result.data;
        dispatch({
          type: GET_HOT_PAGES,
          payload:data
        }); 
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      }else{
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages")
      }
    }catch(err){

      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}

export function markPageAsHot(payload: IMarkHotPageRequest){
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>, store:()=>IApplicationState)=>{
    const result = await axios({
      url:`/api/v1/update-hot-page-status`,
      method:"POST",
      data:payload
    });
    try{

      if(result.status === 200){
        const data = result.data;
        dispatch({
          type: MARK_PAGE_AS_HOT,
          payload:data
        }); 
        // message.success("Page hidden");
        //         //@ts-ignore
        //         dispatch(logActivity(`Change page Hot Status ${JSON.stringify(cleanObj({
        //             page:payload.page,
        //             country:payload.country,
        //             scenario:payload.scenario,
        //             strategy:payload.strategy,
        //             scenarios_config:payload.scenarios_config}))}</a>`))
        //@ts-ignore
        dispatch(getHotPages());

        message.success(`Hot page status updated for ${payload.handle_name}`)
        
      }else{
        dispatch(toggleLoading(false));
        message.error("Error, Could not hide  page")
      }
    }catch(err){

      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  }
}




//TOGGLE_LOGIN
export function toggleLoading(payload:boolean){
  return{
    type:HOT_PAGES_LOADING,
    payload
  }
}


//LOGIN_LOADING
function handleLoading(state:IHotPagesState, action:Action) {
  return update(state, {
    isLoading:{
      $set:action.payload
    }
  })
}


function handleGetHotPages(state:IHotPagesState, action:Action) {
  return update(state, {
    hotPages:{
      $set:action.payload
    }
  })
}

function handleMarkPageAsHot(state:IHotPagesState, action:Action) {
  return update(state, {
    hotPage:{
      $set:action.payload
    }
  })
}


const ACTION_HANDLERS = {
  HOT_PAGES_LOADING:handleLoading,
  GET_HOT_PAGES:handleGetHotPages,
  MARK_PAGE_AS_HOT:handleMarkPageAsHot
};

const initialState = {
  isLoading:false
};

export default function HotPagesReducer(state = initialState, action:Action) {
  const handler = ACTION_HANDLERS[action.type];
  //@ts-ignore
  return handler ? handler(state, action) : state;
}
