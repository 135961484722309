import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { logActivity } from "../../ActivityLog/module";
import { IApplicationState } from "../../../store/reducers";
import { message } from "antd";
import { IAntiFraudRule } from "../../../typings";

const {
  GET_ANTI_FRAUD_RULES,
  UPDATE_ANTI_FRAUD_RULES,
  TOGGLE_EDIT_ANTI_FRAUD_RULES_MODAL,
  ANTI_FRAUD_RULES_LOADING,
  CREATE_ANTI_FRAUD_RULE,
  DELETE_ANTI_FRAUD_RULE,
  SET_ANTI_FRAUD_RULE,
} = constants;

export interface IAntiFraudRulesState {
  antiFraudRules?: Array<IAntiFraudRule>;
  showEditModal: boolean;
  isLoading: boolean;
  scenariosServices?: {
    [key: string]: {
      scenarios: Array<string>;
      services: Array<string>;
    };
  };
}

type Action =
  | {
      type: "GET_ANTI_FRAUD_RULES";
      payload: any;
    }
  | {
      type: "UPDATE_ANTI_FRAUD_RULES";
      payload: string;
    }
  | {
      type: "TOGGLE_EDIT_ANTI_FRAUD_RULES_MODAL";
      payload: string;
    }
  | {
      type: "ANTI_FRAUD_RULES_LOADING";
      payload: string;
    };

export function setAntiFraudRule(payload: {
  xcid: string;
  anti_fraud_rule_id: number;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      url: `/api/v1/set-anti-fraud-rule`,
      method: "patch",
      data: payload,
    });
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: SET_ANTI_FRAUD_RULE,
          payload: data,
        });
        dispatch(toggleLoading(false));
        message.success("Anti Fraud Rule set successfully!");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(`Anti Fraud Rule Set to ${payload.xcid}`)
        );
        //@ts-ignore
        dispatch(getPublishedPages());
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not set Anti Fraud ID");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getAntiFraudRules() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-all-anti-fraud-rules`);

      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_ANTI_FRAUD_RULES,
          payload: data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get legals");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function toggleEditModal(payload: boolean) {
  return {
    type: TOGGLE_EDIT_ANTI_FRAUD_RULES_MODAL,
    payload,
  };
}

export function updateAntiFraudRule(payload: IAntiFraudRule) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: "patch",
        url: `/api/v1/update-anti-fraud-rule/${payload.id}`,
        data: payload,
      });
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: UPDATE_ANTI_FRAUD_RULES,
          payload: data,
        });
        dispatch(toggleLoading(false));
        message.success(`Anti Fraud rule updated!`);
        //@ts-ignore
        dispatch(
          // @ts-ignore
          logActivity(
            `Updated Anti Fraud rule for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario_name} Fraud Tool: ${payload.fraud_tool} `
          )
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        dispatch(toggleLoading(false));
        message.error("Error while updating Anti Fraud rule!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function createAntiFraudRule(payload: IAntiFraudRule) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: "post",
        url: `/api/v1/add-anti-fraud-rule`,
        data: payload,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: CREATE_ANTI_FRAUD_RULE,
          payload: result.data,
        });
        //@ts-ignore
        //dispatch(getLegals());
        dispatch(toggleLoading(false));
        message.success("Anti Fraud Rule created successfully");
        //@ts-ignore
        dispatch(
          // @ts-ignore
          logActivity(
            `Added Anti Fraud for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario_name} Fraud Tool: ${payload.fraud_tool} `
          )
        );
        setTimeout(() => {
          window.location.href = `/legals/browse`;
        }, 1000);
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not create anti fraud rule!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function deleteAntiFraudRule(payload: IAntiFraudRule) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: "delete",
        url: `/api/v1/delete-anti-fraud-rule`,
        data: payload,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: DELETE_ANTI_FRAUD_RULE,
          payload: result.data,
        });
        //@ts-ignore
        dispatch(getLegals());
        dispatch(toggleLoading(false));
        message.success("Anti Fraud Rule deleted successfully");
        //@ts-ignore
        dispatch(
          // @ts-ignore
          logActivity(
            `Deleted AntiFraud rule for County: ${payload.country} Service: ${payload.service} Scenario: ${payload.scenario_name} Fraud Tool: ${payload.fraud_tool} `
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not delete anti fraud rule!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: ANTI_FRAUD_RULES_LOADING,
    payload,
  };
}

function handleAntiFraudRules(state: IAntiFraudRulesState, action: Action) {
  return update(state, {
    antiFraudRules: {
      $set: action.payload,
    },
  });
}

function handleToggleEditModal(state: IAntiFraudRulesState, action: Action) {
  return update(state, {
    showEditModal: {
      $set: action.payload,
    },
  });
}

//LOGIN_LOADING
function handleLoading(state: IAntiFraudRulesState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

function handleCreateLegals(state: IAntiFraudRulesState, action: Action) {
  return update(state, {
    createdLegals: {
      $set: action.payload,
    },
  });
}

function handleUpdateLegals(state: IAntiFraudRulesState, action: Action) {
  return update(state, {
    legals: {
      $set: action.payload,
    },
  });
}

function handleGetAllScenariosServices(
  state: IAntiFraudRulesState,
  action: Action
) {
  return update(state, {
    scenariosServices: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS = {
  GET_ANTI_FRAUD_RULES: handleAntiFraudRules,
  TOGGLE_EDIT_ANTI_FRAUD_RULES_MODAL: handleToggleEditModal,
  ANTI_FRAUD_RULES_LOADING: handleLoading,
  CREATE_ANTI_FRAUD_RULE: handleCreateLegals,
  UPDATE_ANTI_FRAUD_RULES: handleUpdateLegals,
  GET_ALL_SCENARIOS_SERVICES: handleGetAllScenariosServices,
};

const initialState = {
  showEditModal: false,
  isLoading: false,
};

export default function AntiFraudRulesReducer(
  state = initialState,
  action: Action
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
