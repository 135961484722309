// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from "axios";

//TYPINGS
import { IUser } from "../../../typings";

const {
  ESTABLISH_CURRENT_USER
} = constants;

export interface ILogInInReducerState {
  loginInput: {
    email:string,
    password:string,
    full_name:string,
    phone_number:string,
    avatar:string,
  };
  currentUser?:IUser;
  isAuthenticated:boolean,
  isLoading:boolean
}


type Action = { 
  type: "GET_LOGIN_INPUT",
  payload?:{key:string, value:string}
}
|
{ 
  type: "ESTABLISH_CURRENT_USER",
  payload?:{
    avatar:string,
    username:string,
    token:string,
  }
};



export function establishCurrentUser(){
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>)=>{
    const params = new URLSearchParams(document.location.search.substring(1));
    const username = params.get("username");
    const token = params.get("token") || localStorage.getItem("token");
    const img = params.get("img");
    const currentUser = username ? {
      avatar:img,
      username:username,
      token:token
      //@ts-ignore
    } : JSON.parse(localStorage.getItem("currentUser"));
    if(token && currentUser){
      dispatch({
        type:ESTABLISH_CURRENT_USER,
        payload:currentUser as IUser
      });
      if(!localStorage.getItem("token")){
        localStorage.setItem("token", token);
      }    
      if(!localStorage.getItem("currentUser")){
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
      }
      axios.defaults.headers.common = {
        authorization: token,
        'Content-Type': 'application/json',
      };
    }
  }
}
//LOGIN_LOADING
function handleLoading(state:ILogInInReducerState, action:Action) {
  return update(state, {
    isLoading:{
      $set:action.payload
    }
  })
}


function handleEstablishCurrentUser(state:ILogInInReducerState, action:Action) {
  return update(state, {
    isAuthenticated:{
      //@ts-ignore
      $set:action.payload.token ? true : false
    },
    currentUser:{
      $set:action.payload
    }
  })
}

const ACTION_HANDLERS = {
  ESTABLISH_CURRENT_USER:handleEstablishCurrentUser
};

const initialState = {
  loginInput:{
    email:"",
    password:"",
    avatar:"",
    full_name:"",
    phone_number:"",
  },
  isAuthenticated:false,
  isLoading:false
};

export default function LoginReducer(state = initialState, action:Action) {
  //@ts-ignore
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
