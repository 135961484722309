import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

//TYPINGS
import { IApplicationState } from "../../store/reducers";

const UnauthenticatedRoute:React.FC<{component:React.FC<any>}> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state:IApplicationState) => state.login.isAuthenticated);
  const query = new URL(window.location.href);
  const redirectPath = window.location.pathname + window.location.search;
  const redirect = window.location.search.includes("redirect") ?
  redirectPath.slice(redirectPath.indexOf("redirect=") + "redirect=".length) :
  "/";

  

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect to={redirect} />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;
