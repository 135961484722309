import React from "react";

//ASSETS
import styles from "./Footer.module.scss";

interface IFooterProps {

}
const Footer:React.FC<IFooterProps> = ({}) => {
  return (
  <footer className="ant-layout-footer" style={{padding: 0}}>
    <footer className="ant-pro-global-footer">
      <div className="ant-pro-global-footer-links">
        <a title="Ant Design Pro" target="_blank"
          href="#">Ouisys Panel
        </a>
      </div>
      {/* <div className="ant-pro-global-footer-copyright">Copyright 2019</div> */}
    </footer>
  </footer>
  );
  };
  export default Footer;