// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { IApplicationState } from "../../../store/reducers";
import { message } from "antd";
import queryString from "query-string";
import { logActivity } from "../../ActivityLog/module";
import { IConfig, IStrategy } from "../../../typings";
const {
  GET_HANDLE_CONFIG,
  CONFIGS_LOADING,
  CREATE_STRATEGY,
  GET_COUNTRIES_OPERATORS,
  GET_STRATEGIES,
  CREATE_SCENARIO,
  DELETE_STRATEGY,
} = constants;
export interface ICreateStrategyRequest {
  country: string;
  strategy: string;
  scenario_config_name: string;
  scenario_config: string;
}

export interface IConfigsReducerState {
  extractedConfig?: any;
  isLoading: boolean;
  countriesAndOperators?: any;
  strategies?: any;
}

type Action =
  | {
      type: "GET_HANDLE_CONFIG";
      payload: any;
    }
  | {
      type: "CONFIGS_LOADING";
      payload: any;
    }
  | {
      type: "CREATE_STRATEGY";
      payload: any;
    }
  | {
      type: "GET_COUNTRIES_OPERATORS";
      payload: any;
    }
  | {
      type: "GET_STRATEGIES";
      payload: any;
    }
  | {
      type: "CREATE_SCENARIO";
      payload: any;
    };

//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: CONFIGS_LOADING,
    payload,
  };
}
export function getHandleConfig(payload: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await fetch(
        `/api/v1/get_handle?${queryString.stringify(payload)}`
      );
      const data = await result.json();
      console.log(result);
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        //const data = result.data;
        dispatch({
          type: GET_HANDLE_CONFIG,
          payload: {
            key: payload.key,
            value: data,
          },
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to extract");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      message.error(err.message);
    }
  };
}

export function createStrategy(payload: ICreateStrategyRequest) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const { currentUser } = store().login;
    try {
      const result = await axios({
        method: "post",
        url: `/api/v1/create_strategy`,
        data: {
          ...payload,
          creator: currentUser !== undefined ? currentUser.username : "",
        },
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: CREATE_STRATEGY,
          payload: result.data,
        });
        //@ts-ignore
        //dispatch(getLegals());
        dispatch(toggleLoading(false));
        message.success("Strategy created successfully");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Created strategy <a href="https://panel.ouisys.com/configs/strategies/browse?${queryString.stringify(
              payload
            )}" target="_blank" rel="noopener noreferrer">${JSON.stringify(
              payload
            )}</a>`
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not create strategy!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function createScenarioConfig(payload: IConfig) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const { currentUser } = store().login;
      const result = await axios({
        method: "post",
        url: `/api/v1/create_scenario`,
        data: {
          ...payload,
          creator: currentUser !== undefined ? currentUser.username : "",
        },
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: CREATE_SCENARIO,
          payload: result.data,
        });
        //@ts-ignore
        //dispatch(getLegals());
        dispatch(toggleLoading(false));
        message.success("Scenario created successfully");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Created scenario <a href="https://panel.ouisys.com/configs/scenarios/browse?${queryString.stringify(
              { scenario: payload.slug, ...payload }
            )}" target="_blank" rel="noopener noreferrer">${JSON.stringify({
              scenario: payload.slug,
              ...payload,
            })}</a>`
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not create scenario!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
    }
  };
}

export function deleteStrategy(payload: IStrategy) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: "post",
        url: `/api/v1/delete-strategy`,
        data: payload,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: DELETE_STRATEGY,
          payload: result.data,
        });
        //@ts-ignore
        dispatch(getStrategies());
        dispatch(toggleLoading(false));
        message.success("Strategy Text deleted successfully");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Deleted Strategy text for ID ${payload.id} County: ${payload.country} Strategy: ${payload.strategy} Strategy Config Name: ${payload.scenario_config_name}`
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not delete strategy!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getCountriesOperators() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: "get",
        url: `/api/v1/get_countries_operators`,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        dispatch({
          type: GET_COUNTRIES_OPERATORS,
          payload: result.data,
        });
        //@ts-ignore
        //dispatch(getLegals());
        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not get countries and operators!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getStrategies() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get_strategies`);

      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_STRATEGIES,
          payload: data,
        });

        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get campaigns");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getScenarios() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get_scenarios`);

      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_STRATEGIES,
          payload: data,
        });

        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get scenarios");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

function handleGetHandleConfig(state: IConfigsReducerState, action: Action) {
  const { key, value } = action.payload;
  const data = value.data[0];
  const config = {
    country: data.country.toLowerCase(),
    scenario: data.slug,
    device: data.deviceClass,
    service: data["brand-domain"].split(".")[1],
    host: data["brand-domain"],
  };
  return update(state, {
    extractedConfig: {
      [key]: {
        $set: config,
      },
    },
  });
}

//LOGIN_LOADING
function handleLoading(state: IConfigsReducerState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

function handleGetCountriesOperators(
  state: IConfigsReducerState,
  action: Action
) {
  return update(state, {
    countriesAndOperators: {
      $set: action.payload,
    },
  });
}

function handleGetStrategies(state: IConfigsReducerState, action: Action) {
  return update(state, {
    strategies: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS = {
  GET_HANDLE_CONFIG: handleGetHandleConfig,
  CONFIGS_LOADING: handleLoading,
  GET_COUNTRIES_OPERATORS: handleGetCountriesOperators,
  GET_STRATEGIES: handleGetStrategies,
};

const initialState = {
  extractedConfig: {},
};

export default function ConfigsReducer(state = initialState, action: Action) {
  //@ts-ignore
  const handler = ACTION_HANDLERS[action.type];
  //@ts-ignore
  return handler ? handler(state, action) : state;
}
