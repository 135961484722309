import { Avatar, Icon, Menu, Spin, message } from 'antd';
import { ClickParam } from 'antd/es/menu';
import React from 'react';
import HeaderDropdown from '../HeaderDropdown';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../../store/reducers';


const AvatarDropdown: React.SFC<any> = props => {

    const {  currentUser } = useSelector((state: IApplicationState) => state.login);

    const menuHeaderDropdown = (
      <Menu className="menu" selectedKeys={[]} >

          {/* <Menu.Item key="center">
            <Icon type="user" />
            User
          </Menu.Item>

 
          <Menu.Item key="settings">
            <Icon type="setting" />
            Settings
          </Menu.Item>
        
         <Menu.Divider /> */}



        <Menu.Item key="logout" onClick={()=>{
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("currentUser");
          window.location.href = "/login"
        }}>
          <Icon type="logout" />
          Logout
        </Menu.Item>





      </Menu>
    );

    return  true ? (
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span>
          <Avatar style={{padding:0}} size="small"  alt="avatar" src={currentUser && currentUser.avatar} />
          <span>{currentUser && currentUser.username}</span>
        </span>
      </HeaderDropdown>
    ) :
    (
      <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />

    );
  }

export default AvatarDropdown;
