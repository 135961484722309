import { combineReducers, Reducer } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import login, { ILogInInReducerState } from "../routes/Login/module";
import home, { IHomeReducerState } from "../routes/Home/module";
import hotpages, { IHotPagesState } from "../routes/HotPages/module";
import activityLog, {
  IActivityLogReducerState,
} from "../routes/ActivityLog/module";
import campaigns, { ICampaignsState } from "../routes/Campaigns/module";
import pages, { IPagesState } from "../routes/Pages/module";
import legals, { ILegalsState } from "../routes/Legals/module";
import abtests, { IABTestsReducerState } from "../routes/ABTests/module";
import contents, { IContentsState } from "../routes/Contents/module";
import configs, { IConfigsReducerState } from "../routes/Configs/module";
import splitTraffic, {
  ISplitTrafficReducerState,
} from "../routes/SplitTraffic/module";
import antiFraudRules, {
  IAntiFraudRulesState,
} from "../routes/AntiFraudRules/module";

export interface IApplicationState {
  router: RouterState;
  login: ILogInInReducerState;
  home: IHomeReducerState;
  hotpages: IHotPagesState;
  activityLog: IActivityLogReducerState;
  campaigns: ICampaignsState;
  pages: IPagesState;
  legals: ILegalsState;
  abtests: IABTestsReducerState;
  contents: IContentsState;
  configs: IConfigsReducerState;
  splitTraffic: ISplitTrafficReducerState;
  antiFraudRules: IAntiFraudRulesState;
}

export default (history: History) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history) as Reducer,
    login,
    home,
    hotpages,
    activityLog,
    campaigns,
    pages,
    legals,
    abtests,
    contents,
    //@ts-ignore
    configs,
    splitTraffic,
    antiFraudRules,
  });
