// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { IApplicationState } from "../../../store/reducers";
import { message } from "antd";
import { ICampaign, ISummaryofNumbers } from "../../../typings";

const { HOME_LOADING, GET_SUMMARY_OF_NUMBERS, HOME_GET_CAMPAIGNS } = constants;

export interface IHomeReducerState {
  campaigns: ICampaign[];
  summaryOfNumbers: Array<ISummaryofNumbers>;
  totalSales?: number;
  totalVisits?: number;
  totalLastWeekSales: number;
  totalLastWeekVisits: number;
}

type Action =
  | {
      type: "GET_SUMMARY_OF_NUMBERS";
      payload: any;
    }
  | {
      type: "HOME_GET_CAMPAIGNS";
      payload: any;
    };

//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: HOME_LOADING,
    payload,
  };
}

export function getCampaigns() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-campaigns`);
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;

        const finalResult = {} as ICampaign;
        for (var i = 0; i < data.length; i++) {
          //@ts-ignore
          finalResult[data[i].xcid] = data[i];
        }

        dispatch({
          type: HOME_GET_CAMPAIGNS,
          payload: finalResult,
        });

        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get campaigns");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getSummaryOfNumbers() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await fetch(
        `https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rLhOo6e/versions/13/execute/10800`
      );

      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = await result.json();
        dispatch({
          type: GET_SUMMARY_OF_NUMBERS,
          payload: data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get summary of numbers");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

function handleGetSummaryOfSales(state: IHomeReducerState, action: Action) {
  //@ts-ignore
  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop];
    }
    return total;
  };
  const summaryOfNumbers = action.payload;
  const totalSales = action.payload.sum("sales");
  const totalVisits = action.payload.sum("visits");
  const totalLastWeekSales = action.payload.sum("sales_last_week");
  const totalLastWeekVisits = action.payload.sum("visits_last_week");

  return update(state, {
    summaryOfNumbers: {
      $set: summaryOfNumbers,
    },
    totalSales: {
      $set: totalSales,
    },
    totalVisits: {
      $set: totalVisits,
    },
    totalLastWeekSales: {
      $set: totalLastWeekSales,
    },
    totalLastWeekVisits: {
      $set: totalLastWeekVisits,
    },
  });
}
const ACTION_HANDLERS = {
  GET_SUMMARY_OF_NUMBERS: handleGetSummaryOfSales,
};

const initialState = {};

export default function HomeReducer(state = initialState, action: any) {
  //@ts-ignore
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
