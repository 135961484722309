export default {
  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  CHANGE_CAMPAIGNS_CONFIG_SUCCESS: "CHANGE_CAMPAIGNS_CONFIG_SUCCESS",
  TOGGLE_EDIT_CAMPAIGN_MODAL: "TOGGLE_EDIT_CAMPAIGN_MODAL",
  CAMPAIGNS_LOADING: "CAMPAIGNS_LOADING",
  RESET_CAMPAIGNS: "RESET_CAMPAIGNS",
  UPDATE_CAMPAIGN_STATUS: "UPDATE_CAMPAIGN_STATUS",
  UPDATE_MULTIPLE_CAMPAIGN_STATUS: "UPDATE_MULTIPLE_CAMPAIGN_STATUS",
  EXPORT_EXCEL: "EXPORT_EXCEL",
  GET_PUBLISHED_PAGES_CAMPAIGNS: "GET_PUBLISHED_PAGES_CAMPAIGNS",
  GET_SOURCES: "GET_SOURCES",
  FIND_CAMPAIGNS: "FIND_CAMPAIGNS",
  CREATE_MULTIPLE_CAMPAIGNS: "CREATE_MULTIPLE_CAMPAIGNS",
  GET_CAMPAIGN_STATS: "GET_CAMPAIGN_STATS",
  GET_CAMPAIGN_STATS_CALL_MADE: "GET_CAMPAIGN_STATS_CALL_MADE",
  CREATE_SAFE_LINK: "CREATE_SAFE_LINK",
  RESET_SAFE_LINK: "RESET_SAFE_LINK",
  CLONE_CAMPAIGN: "CLONE_CAMPAIGN",
  UPDATE_CHAIN_LINK: "UPDATE_CHAIN_LINK",
  ADD_CLOAKING_CONFIG_SUCCESS: "ADD_CLOAKING_CONFIG_SUCCESS",
  REMOVE_CLOAKING_CONFIG_SUCCESS: "REMOVE_CLOAKING_CONFIG_SUCCESS",
};
