import React from "react";

class ErrorHandler extends React.Component<{},{errorOccurred:boolean}> {
  constructor(props:any) {
    super(props)
    this.state = { errorOccurred: false }
  }

  componentDidCatch(error: Error | null, info: object) {
    this.setState({ errorOccurred: true })
    //logErrorToMyService(error, info)
  }

  render() {
    return this.state.errorOccurred ? <h1>Something went wrong!</h1> : this.props.children
  }
}

export default ErrorHandler