export default {
  EXPORT_EXCEL: "EXPORT_EXCEL",
  GET_PUBLISHED_PAGES: "GET_PUBLISHED_PAGES",
  PAGES_LOADING: "PAGES_LOADING",
  GET_UNPUBLISHED_PAGES: "GET_UNPUBLISHED_PAGES",
  PUBLISH_PAGE: "PUBLISH_PAGE",
  PUBLISH_CREATE_CAMPAIGN: "PUBLISH_CREATE_CAMPAIGN",
  RESET_PUBLISH_CAMPAIGN: "RESET_PUBLISH_CAMPAIGN",
  HIDE_PUBLISHED_PAGE: "HIDE_PUBLISHED_PAGE",
  GENERATE_LEGALS: "GENERATE_LEGALS",
  GET_PAGE_STATS: "GET_PAGE_STATS",
  MARK_AS_TESTED: "MARK_AS_TESTED",
  GET_HOT_PAGES: "GET_HOT_PAGES",
  SET_TAGS: "SET_TAGS",
};
