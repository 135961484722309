import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory, createMemoryHistory } from "history";
import createRootReducer from "./reducers";

declare global {
  interface Window {
    devToolsExtension?: any;
    __PRELOADED_STATE__?: any;
    GLOBAL_CONSTANTS: {
      API_URL: string;
      GOOGLE_MAPS_API_KEY: string;
    };
  }
}
// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export default (url = "/") => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory();

  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === "development" && !isServer) {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  // logger
  const log = createLogger({ diff: true, collapsed: true });

  //middleware
  const middleware = [thunk, routerMiddleware(history)];
  if (process.env.NODE_ENV === "development") {
    middleware.push(log);
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );
  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    composedEnhancers
  );

  return {
    store,
    history,
  };
};
