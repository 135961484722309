// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { IApplicationState } from "../../../store/reducers";
import { message } from "antd";
import {
  ICampaign,
  IPublishedPage,
  IAbTests,
  IAbTestReport,
} from "../../../typings";
import * as R from "ramda";
import { logActivity } from "../../ActivityLog/module";

const {
  ABTESTS_LOADING,
  GET_AB_TESTS,
  CREATE_AB_TEST,
  LOG_ACTIVITY,
  GET_PAGES,
  STOP_AB_TEST,
  GET_ABTEST_REPORT,
  GET_AB_TESTS_EXPIRED,
  VALIDATE_ABTEST_NAME,
} = constants;

export interface IABTestsReducerState {
  abtests: Array<IAbTests>;
  abtestsExpired: Array<IAbTests>;
  isLoading: boolean;
  pages: Array<IPublishedPage>;
  countries: Array<string>;
  abTestReport: Array<IAbTestReport>;
  isAbTestNameValid: boolean;
}

type Action =
  | {
      type: "GET_AB_TESTS";
      payload: any;
    }
  | {
      type: "CREATE_AB_TEST";
      payload: any;
    }
  | {
      type: "GET_PAGES";
      payload: any;
    }
  | {
      type: "STOP_AB_TEST";
      payload: any;
    }
  | {
      type: "GET_ABTEST_REPORT";
      payload: any;
    };

const getUniqueCountries = async (pages: Array<IPublishedPage>) => {
  const u = R.pipe(
    R.map((o: any) => o.country as string),
    R.uniq,
    R.sortBy((x) => x)
  )(pages);
  return u;
};
//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: ABTESTS_LOADING,
    payload,
  };
}

export function getABTests() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-ab-tests?type=active`);
      if (result.status === 200 && result.data) {
        dispatch(toggleLoading(false));
        dispatch({
          type: GET_AB_TESTS,
          payload: result.data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get activity log");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getABTestsExpired() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-ab-tests?type=expired`);
      if (result.status === 200 && result.data) {
        dispatch(toggleLoading(false));
        dispatch({
          type: GET_AB_TESTS_EXPIRED,
          payload: result.data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get activity log");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getPages() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(`/api/v1/get-released-pages`);
    try {
      dispatch(toggleLoading(true));
      if (result.status === 200) {
        const data = result.data;
        const countries = await getUniqueCountries(data);
        dispatch({
          type: GET_PAGES,
          payload: { countries, data },
        });
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))

        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function getAbTestReport({
  country,
  owl_name,
  start_date,
}: {
  country: string;
  owl_name: string;
  start_date: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(
      `/api/v1/ab-test-report?country=${country}&owl_name=${owl_name}&start_date=${start_date}`
    );
    try {
      dispatch(toggleLoading(true));
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: GET_ABTEST_REPORT,
          payload: data,
        });
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))

        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function createAbTest(payload: {
  pageA: IPublishedPage;
  pageB: IPublishedPage;
  description: string;
  xcids: Array<string>;
  abTestName: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const currentUser = store().login.currentUser;
    try {
      if (currentUser) {
        dispatch(toggleLoading(true));
        const result = await axios({
          method: "post",
          url: `/api/v1/create-abtest`,
          data: {
            username: currentUser.username,
            ...payload,
          },
        });
        if (result.status === 200) {
          dispatch({
            type: CREATE_AB_TEST,
            payload: result.data,
          });

          dispatch(toggleLoading(false));
          //@ts-ignore
          dispatch(
            //@ts-ignore

            logActivity(
              `Created A/B Test <a href="https://panel.ouisys.com/abtests/history?page_a=${payload.pageA.page}&?page_b=${payload.pageB.page}&ab_test_id=${result.data.ab_test_id}"&ab_test_name=${payload.abTestName} target="_blank" rel="noopener noreferrer">Name: ${payload.abTestName}, Page A:${payload.pageA.page} & Page B:${payload.pageB.page} </a>`
            )
          );
          message.success("A/B Test Started Successfully!");
          setTimeout(() => {
            window.location.href = "/abtests/history";
          }, 2000);
        } else {
          message.error("Error, Failed to create abtest");
        }
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function stopAbTest({
  id,
  page_a,
  page_b,
  ab_test_name,
}: {
  id: number;
  page_a: string;
  page_b: string;
  ab_test_name: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const currentUser = store().login.currentUser;
    try {
      dispatch(toggleLoading(true));
      const result = await axios({
        method: "post",
        url: `/api/v1/stop-ab-test`,
        data: {
          id,
        },
      });
      if (result.status === 200) {
        dispatch({
          type: STOP_AB_TEST,
          payload: result.data,
        });

        dispatch(toggleLoading(false));
        message.success("A/B Test Stopped Successfully!");
        //@ts-ignore
        dispatch(
          //@ts-ignore

          logActivity(
            `Stopped A/B Test <a href="https://panel.ouisys.com/abtests/history?page_a=${page_a}&?page_b=${page_b}&ab_test_id=${id}&ab_test_name=${ab_test_name}" target="_blank" rel="noopener noreferrer">Name: ${ab_test_name}, Page A:${page_a} & Page B:${page_b} </a>`
          )
        );
        //@ts-ignore
        dispatch(getABTests());
        //@ts-ignore
        dispatch(getABTestsExpired());
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to create abtest");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function validateTestName(
  name: string,
  type: "abtest" | "split-traffic"
) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    try {
      const result = await axios({
        method: "get",
        url: `/api/v1/check-test-name`,
        params: {
          name,
          type,
        },
      });
      if (result.status === 200) {
        dispatch({
          type: VALIDATE_ABTEST_NAME,
          payload: result.data,
        });
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Validating abtest");
      }
    } catch (err) {
      dispatch(toggleLoading(false));

      message.error("Error, Validating abtest");
      // message.error(JSON.stringify(err))
    }
  };
}

function handleGetABTests(state: IABTestsReducerState, action: Action) {
  return update(state, {
    abtests: {
      $set: action.payload,
    },
  });
}

function handleLoading(state: IABTestsReducerState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

function handleLogActivity(state: IABTestsReducerState, action: Action) {
  return update(state, {
    loggedActivity: {
      $set: action.payload,
    },
  });
}

function handleGetPages(state: IABTestsReducerState, action: Action) {
  return update(state, {
    pages: {
      $set: action.payload.data,
    },
    countries: {
      $set: action.payload.countries,
    },
  });
}

function handleGetAbtestReport(state: IABTestsReducerState, action: Action) {
  return update(state, {
    abTestReport: {
      $set: action.payload,
    },
  });
}

function handleGetExpiredTests(state: IABTestsReducerState, action: Action) {
  return update(state, {
    abtestsExpired: {
      $set: action.payload,
    },
  });
}

function handleValidateAbtest(state: IABTestsReducerState, action: Action) {
  return update(state, {
    isAbTestNameValid: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS = {
  ABTESTS_LOADING: handleLoading,
  GET_AB_TESTS: handleGetABTests,
  LOG_ACTIVITY: handleLogActivity,
  GET_PAGES: handleGetPages,
  GET_ABTEST_REPORT: handleGetAbtestReport,
  GET_AB_TESTS_EXPIRED: handleGetExpiredTests,
  VALIDATE_ABTEST_NAME: handleValidateAbtest,
};

const initialState = {
  isLoading: false,
};

export default function ABTestsReducer(state = initialState, action: any) {
  //@ts-ignore
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
