export default {
  ABTESTS_LOADING: "ABTESTS_LOADING",
  CREATE_SPLIT_TRAFFIC_TEST: "CREATE_SPLIT_TRAFFIC_TEST",
  GET_SPLIT_TRAFFIC_TESTS: "GET_SPLIT_TRAFFIC_TESTS",
  LOG_ACTIVITY: "LOG_ACTIVITY",
  GET_PAGES: "GET_PAGES",
  STOP_SPLIT_TRAFFIC_TEST: "STOP_SPLIT_TRAFFIC_TEST",
  GET_SPLIT_TRAFFIC_REPORT: "GET_SPLIT_TRAFFIC_REPORT",
  GET_SPLIT_TRAFFIC_TESTS_EXPIRED: "GET_SPLIT_TRAFFIC_TESTS_EXPIRED",
  GET_SPLIT_TRAFFIC_TEST: "GET_SPLIT_TRAFFIC_TEST",
  EDIT_SPLIT_TRAFFIC_TEST: "EDIT_SPLIT_TRAFFIC_TEST",
};
