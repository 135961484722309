
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function WindowFocusHandler() {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    onFocus()
    // window.addEventListener("focus", onFocus)
    // return () => {
    //   window.removeEventListener("focus", onFocus)
    // }
  }, [])

  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("currentUser");
    window.location.href = "/login";
  }
  const onFocus = () => {
    const token = localStorage.getItem("token") || "";
    if(token !== ""){
      fetch("/api/v1/session-check",{
        "headers":{
          "authorization": `${token}`,
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          if(res.status === 401){
            logout();
          }
        }).catch(err => {
         console.warn(err);
          logout();
        })
    }else{
      logout()
    }
  }
  

  return <></>
}