export default {
  ABTESTS_LOADING:"ABTESTS_LOADING",
  CREATE_AB_TEST:"CREATE_AB_TEST",
  GET_AB_TESTS:"GET_AB_TESTS",
  LOG_ACTIVITY:"LOG_ACTIVITY",
  GET_PAGES:"GET_PAGES",
  STOP_AB_TEST:"STOP_AB_TEST",
  GET_ABTEST_REPORT:"GET_ABTEST_REPORT",
  GET_AB_TESTS_EXPIRED:"GET_AB_TESTS_EXPIRED",
  VALIDATE_ABTEST_NAME:"VALIDATE_ABTEST_NAME"
};