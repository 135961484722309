//LIBRARIES
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Frontload } from "react-frontload";

//COMPONENTS
import App from './App';
import ErrorHandler from './components/ErrorHandler';

//UTILS
import * as serviceWorker from './serviceWorker';
import createStore from "./store";
import config from "./config";

//STYLES
import "./styles/base.scss";

window.GLOBAL_CONSTANTS = config;

const { store, history } = createStore();

ReactDOM.render(
  <ErrorHandler>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Frontload noServerRender>
          <App />
        </Frontload>
      </ConnectedRouter>
    </Provider>
  </ErrorHandler>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
