import { Button, Icon, message, Tooltip } from 'antd';
import React from 'react';

import Avatar from './AvatarDropdown';
import HeaderSearch from '../HeaderSearch';
import styles from "./index.module.scss";
import { IApplicationState } from '../../store/reducers';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const GlobalHeaderRight: React.SFC<any> = props => {
  const { theme, layout } = props;
  const {  currentUser } = useSelector((state: IApplicationState) => state.login);

  // let className = styles.right;

  // if (theme === 'dark' && layout === 'topmenu') {
  //   className = `${styles.right}  ${styles.dark}`;
  // }

  return (
    <div className={styles.right}>

      <Tooltip
        title="Help"
      >
        <a
          target="_blank"
          href="slack://channel?team=T028Y7L94&id=CNYNV0ZK3"
          rel="noopener noreferrer"
          //className={styles.action}
        >
          <Icon type="question-circle-o" />
        </a>
      </Tooltip>
      <a type="ghost" key="clear-cache" onClick={()=>{
        fetch("/api/v1/clear-cache").then((res)=>{
          message.success("Cache Cleared")
        }).catch((err)=>{
          console.error("Could Not Clear")
        })
      }}>
        <Icon type="clear-outine" />
        Clear Cache
      </a>
      <Avatar />
    </div>
  );
};

export default GlobalHeaderRight;
