// LIBRARIES
import update from "react-addons-update";
import constants from "./actionConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import fileDownload from "js-file-download";
import enqueue from "../../../utils/fetchQueue";
import cleanObj from "../../../utils/cleanObj";
import queryString from "query-string";

import { IApplicationState } from "../../../store/reducers";
import { ICampaign, IPublishedPage, ISource } from "../../../typings";
import { url } from "inspector";
import { Alert, message } from "antd";
import { ISelectedAffIds } from "../components/CreateCampaign";
import { logActivity } from "../../ActivityLog/module";
import { CloakType } from "../components/AddCloaking";

const {
  GET_CAMPAIGNS,
  CHANGE_CAMPAIGNS_CONFIG_SUCCESS,
  TOGGLE_EDIT_CAMPAIGN_MODAL,
  CAMPAIGNS_LOADING,
  RESET_CAMPAIGNS,
  UPDATE_CAMPAIGN_STATUS,
  UPDATE_MULTIPLE_CAMPAIGN_STATUS,
  EXPORT_EXCEL,
  GET_PUBLISHED_PAGES_CAMPAIGNS,
  GET_SOURCES,
  FIND_CAMPAIGNS,
  CREATE_MULTIPLE_CAMPAIGNS,
  GET_CAMPAIGN_STATS,
  GET_CAMPAIGN_STATS_CALL_MADE,
  CREATE_SAFE_LINK,
  RESET_SAFE_LINK,
  CLONE_CAMPAIGN,
  UPDATE_CHAIN_LINK,
  ADD_CLOAKING_CONFIG_SUCCESS,
  REMOVE_CLOAKING_CONFIG_SUCCESS,
} = constants;

export interface ICampaignsState {
  campaigns: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    search: string;
    data: Array<ICampaign>;
  };
  campaignStats: any;
  campaignStatsCalled: any;
  searchedCamapigns?: Array<ICampaign>;
  createdCampaigns?: Array<ICampaign>;
  sources?: Array<ISource>;
  publishedPages?: Array<IPublishedPage>;
  showEditModal: boolean;
  isLoading: boolean;
  isChangeConfigSuccess: boolean;
  safeLink?: string;
  clonedCampaign: Array<ICampaign>;
}

export interface ICreateSingleCampaignPayload {
  page?: string;
  country?: string;
  scenario?: string;
  strategy?: string;
  scenarios_config?: string;
  comments?: string;
  restrictions?: string;
  source_id: number;
  safe_only?: boolean;
  affiliate_id: string;
  createSafeLink?: boolean;
}
type Action =
  | {
      type: "GET_CAMPAIGNS";
      payload: any;
    }
  | {
      type: "GET_CAMPAIGN_STATS";
      payload: string;
    }
  | {
      type: "GET_CAMPAIGN_STATS_CALL_MADE";
      payload: string;
    }
  | {
      type: "CREATE_SAFE_LINK";
      payload: string;
    };

export function createSafeLink(payload: {
  country: string;
  url: string;
  xcid: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;

      const result = await axios({
        url: `/api/v1/create_safe_link`,
        method: "POST",
        data: payload,
      });
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: CREATE_SAFE_LINK,
          payload: `http://camps.target-mobi.biz/r/${data.data.encoded_url}`,
        });

        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //@ts-ignore
        dispatch(
          //@ts-ignore
          logActivity(
            `Created safe link <a href="http://camps.target-mobi.biz/r/${data.data.encoded_url}">http://camps.target-mobi.biz/r/${data.data.encoded_url}</a>  for camapign ${payload.xcid}`
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to create safe link");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function resetSafeLink() {
  return {
    type: RESET_SAFE_LINK,
  };
}
export function getCampaigns(payload: {
  pageNumber: number;
  pageSize: number;
  filters?: any;
  search?: string;
  sort?: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    try {
      dispatch(toggleLoading(true));
      // Construct query string from pagination, filters, and search
      const { pageNumber, pageSize, filters, search, sort } = payload;
      const queryParams = new URLSearchParams({
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...(search && { search }), // Add search to the query if it exists
        ...(sort && { sort }), // Add sort to the query if it exists
        ...filters, // Spread filters as query parameters
      });

      const result = await axios.get(
        `/api/v2/get-campaigns?${queryParams.toString()}`
      );

      if (result.status === 200) {
        const data = result.data;

        if (data.error) {
          dispatch(toggleLoading(false));
          message.error("Failed to get campaigns. Please reload the page");
          return dispatch({
            type: GET_CAMPAIGNS,
            payload: {
              pageNumber,
              pageSize,
              totalItems: 0,
              data: [],
            },
          });
        }

        // Dispatch campaigns data with the pagination structure
        dispatch({
          type: GET_CAMPAIGNS,
          payload: {
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            totalItems: data.totalItems,
            data: data.data,
            search,
          },
        });
        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Failed to get campaigns");
      }
    } catch (err) {
      console.error(err);
      dispatch(toggleLoading(false));
      message.error("Error, Failed to get campaigns");
    }
  };
}

export function getCampaignStats(payload: string) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch({
      type: GET_CAMPAIGN_STATS_CALL_MADE,
      payload: {
        key: payload,
        value: [],
      },
    });
    try {
      enqueue(
        () =>
          fetch(
            `https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rFVhSbS/versions/2/execute/7200/query?gateways=-&operators=-&xcid=${payload}&from=-30`
          )
            .then((x) => x.json())
            .then((data) =>
              dispatch({
                type: GET_CAMPAIGN_STATS,
                payload: {
                  key: payload,
                  value: data.reverse(),
                },
              })
            )
            .catch((_error) => message.error("Error, Failed to get campaigns"))
        // .then((result)=>{
        //   if(result.status === 200){
        //     const data = result.data;
        //     dispatch({
        //       type: GET_CAMPAIGN_STATS,
        //       payload:{
        //         key:payload,
        //         value:data.reverse()
        //       }
        //     });
        //   }else{
        //     message.error("Error, Failed to get campaigns")
        //   }
        // })
      );
    } catch (err) {
      // message.error(JSON.stringify(err))
    }
  };
}

export function getPublishedPages() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios.get(`/api/v1/get-released-pages`);
    try {
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: GET_PUBLISHED_PAGES_CAMPAIGNS,
          payload: data,
        });
        dispatch(toggleLoading(false));
        // await Promise.all(data.map(async(obj:any)=>{
        //   await takeScreenShot(obj)
        // }))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, Could not get published pages");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function toggleEditModal(payload: boolean) {
  return {
    type: TOGGLE_EDIT_CAMPAIGN_MODAL,
    payload,
  };
}

export function updateCampaignStatus(payload: {
  xcid: string;
  http_status: string;
  campaign_status_change_reason: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;
      const result = await axios({
        method: "post",
        url: `/api/v1/update-campaign-status`,
        data: payload,
      });
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: UPDATE_CAMPAIGN_STATUS,
          payload: data,
        });

        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        if (payload.http_status === "OK") {
          message.success(
            `Campaign ${payload.xcid} ENABLED! The changes may take longer than 5mins to appear.`
          );
          //@ts-ignore
          dispatch(
            //@ts-ignore
            logActivity(
              `Enabled campaign <a href="https://panel.ouisys.com/campaigns/browse?xcid=${payload.xcid}" target="_blank" rel="noopener noreferrer">${payload.xcid}</a>`
            )
          );
        } else {
          message.success(
            `Campaign ${payload.xcid} STOPPED! The changes may take longer than 5mins to appear.`
          );
          //@ts-ignore
          dispatch(
            //@ts-ignore
            logActivity(
              `Disabled campaign <a href="https://panel.ouisys.com/campaigns/browse?xcid=${payload.xcid}" target="_blank" rel="noopener noreferrer">${payload.xcid}</a>`
            )
          );
        }
      } else {
        dispatch(toggleLoading(false));
        message.error("Error while updating campaign!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function updateChainLink(payload: {
  xcid: string;
  chain_link: string | null;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;

      const result = await axios({
        method: "post",
        url: `/api/v1/update-chain-link`,
        data: payload,
      });
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: UPDATE_CHAIN_LINK,
          payload: data,
        });
        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        if (payload.chain_link !== null) {
          message.success(
            `Chain link added to Campaign ${payload.xcid}! The changes may take longer than 5mins to appear.`
          );
          //@ts-ignore
          dispatch(
            //@ts-ignore
            logActivity(
              `Added Chain link ${payload.chain_link} to campaign <a href="https://panel.ouisys.com/campaigns/browse?xcid=${payload.xcid}" target="_blank" rel="noopener noreferrer">${payload.xcid}</a>`
            )
          );
        } else {
          message.success(
            `Chain link removed from Campaign ${payload.xcid}! The changes may take longer than 5mins to appear.`
          );
          //@ts-ignore
          dispatch(
            //@ts-ignore
            logActivity(
              `Chain link removed from Campaign  <a href="https://panel.ouisys.com/campaigns/browse?xcid=${payload.xcid}" target="_blank" rel="noopener noreferrer">${payload.xcid}</a>`
            )
          );
        }
      } else {
        dispatch(toggleLoading(false));
        message.error("Error while updating campaign!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}
export function exportToExcel(payload: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const result = await axios({
      method: "post",
      url: `/api/v1/excel`,
      data: payload,
    });
    if (result.status === 200) {
      const data = result.data;
      dispatch({
        type: EXPORT_EXCEL,
        payload: data,
      });

      const decodeBase64 = (str: any) => {
        try {
          return window.atob(str);
        } catch (e) {
          return str;
        }
      };

      const decoded = decodeBase64(data);
      const buf = new ArrayBuffer(decoded.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== decoded.length; ++i) {
        view[i] = decoded.charCodeAt(i) & 0xff;
      }

      const blob = new Blob([buf], {
        type: "application/octet-stream",
      });
      //window.location.href = data
      // fileDownload(blob, 'data.csv');
      //dispatch(toggleEditModal(false));
      dispatch(toggleLoading(false));
    } else {
      dispatch(toggleLoading(false));
      message.error("Error while exporting!");
    }
  };
}

export function updateMultipleCampaignStatus(
  payload: Array<{
    xcid: string;
    http_status: string;
    campaign_status_change_reason: string;
  }>
) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;

      const result = await axios({
        method: "post",
        url: `/api/v1/update-muiltiple-campaign-status`,
        data: payload,
      });
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: UPDATE_MULTIPLE_CAMPAIGN_STATUS,
          payload: data,
        });
        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success(
          "Campaign Status saved successfully! The changes might take longer than 5mins to appear."
        );
        //@ts-ignore
        dispatch(
          //@ts-ignore
          logActivity(
            `Updated multiple campaigns  <ul>${payload.map(
              (obj) =>
                `<li><a href="https://panel.ouisys.com/campaigns/browse?xcid=${obj.xcid}" target="_blank" rel="noopener noreferrer">${obj.xcid} - ${obj.http_status}</li></a>`
            )}</ul>`
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not update campaign status!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function cloneCampaign(payload: { xcid: string; affid?: string }) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;

      const result = await axios({
        method: "post",
        url: `/api/v1/clone-campaign`,
        data: payload,
      });
      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: CLONE_CAMPAIGN,
          payload: data,
        });
        //@ts-ignore
        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success("Campaign Cloned successfully!");
        //@ts-ignore
        //dispatch(logActivity(`Updated multiple campaigns  <ul>${payload.map((obj)=>`<li><a href="https://panel.ouisys.com/campaigns/browse?xcid=${obj.xcid}" target="_blank" rel="noopener noreferrer">${obj.xcid} - ${obj.http_status}</li></a>`)}</ul>`))
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not clone campaign!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function createMultipleCampaigns(
  payload: Array<ICreateSingleCampaignPayload>
) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const { currentUser } = store().login;

    try {
      const result = await axios({
        method: "post",
        url: `/api/v1/create-muiltiple-campaigns`,
        data: {
          campaigns: payload,
          username: currentUser !== undefined ? currentUser.username : "",
        },
      });
      if (result.status === 200) {
        let data = result.data;
        await Promise.all(
          data.map(async (campaign: ICampaign) => {
            if (
              payload.find(
                (obj) =>
                  obj.affiliate_id === campaign.affiliate_id &&
                  obj.createSafeLink === true
              )
            ) {
              try {
                dispatch(toggleLoading(true));
                const url =
                  campaign.affiliate_id === "FREE-ANY" ||
                  campaign.affiliate_id === "FREE-POP"
                    ? `https://c1.mouisys.com/${campaign.xcid}?offer={offer_id}`
                    : `https://c1.mouisys.com/${campaign.xcid}`;
                const safeLinkresult = await axios({
                  url: `/api/v1/create_safe_link`,
                  method: "POST",
                  data: {
                    url,
                    country: campaign.country,
                    xcid: campaign.xcid,
                    //@ts-ignore
                    filter_globally: campaign.safe_only,
                  },
                });
                if (result.status === 200) {
                  const safeLinkdata = safeLinkresult.data.data;
                  campaign.safe_link = safeLinkdata.safe_link;
                }
                return campaign;
              } catch (err) {
                dispatch(toggleLoading(true));
                throw err;
              }
            }
          })
        );

        dispatch(toggleLoading(false));
        dispatch({
          type: CREATE_MULTIPLE_CAMPAIGNS,
          payload: data,
        });
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success("Campaigns created successfully");
        //@ts-ignore
        dispatch(
          //@ts-ignore
          logActivity(
            `Created campaign(s)  <ul>${data.map(
              //@ts-ignore

              (obj) =>
                `<li><a href="https://panel.ouisys.com/campaigns/browse?xcid=${obj.xcid}" target="_blank" rel="noopener noreferrer">${obj.xcid}</li></a>`
            )}</ul>`
          )
        );
      } else {
        dispatch(toggleLoading(false));
        message.error("Error, could not create campaigns!");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function getSources() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios.get(`/api/v1/get-sources`);
      if (result.status === 200) {
        dispatch(toggleLoading(false));
        const data = result.data;
        dispatch({
          type: GET_SOURCES,
          payload: data,
        });
      } else {
        dispatch(toggleLoading(false));
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function findCampaigns(payload: {
  page: string;
  country: string;
  affid: Array<string>;
  scenario: string;
  strategy: string;
  scenarios_config: string;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    try {
      const result = await axios({
        method: "post",
        url: `/api/v1/find-campaigns`,
        data: payload,
      });

      if (result.status === 200) {
        const data = result.data;
        dispatch({
          type: FIND_CAMPAIGNS,
          payload: data,
        });
        dispatch(toggleLoading(false));
      } else {
        dispatch(toggleLoading(false));

        message.error("Failed to get campaigns");
      }
    } catch (err) {
      dispatch(toggleLoading(false));
      // message.error(JSON.stringify(err))
    }
  };
}

export function changeCampaignsConfig(payload: {
  page: string;
  country: string;
  scenario: string;
  strategy: string;
  scenarios_config: string;
  campaigns?: Array<ICampaign>;
  previousObj?: {
    page: string;
    country: string;
    scenario: string;
    strategy: string;
    scenarios_config: string;
  };
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    dispatch(toggleLoading(true));
    const { pageNumber, pageSize, search } = store().campaigns.campaigns;

    const result = await axios({
      method: "post",
      url: `/api/v1/change-campaigns-config`,
      data: payload,
    });
    if (result && result.status === 200) {
      const data = result.data;
      dispatch({
        type: CHANGE_CAMPAIGNS_CONFIG_SUCCESS,
        payload: data,
      });
      dispatch(
        //@ts-ignore
        getCampaigns({
          pageNumber: 1,
          pageSize: 20,
          search,
        })
      );
      //dispatch(toggleEditModal(false));
      dispatch(toggleLoading(false));
      message.success(
        "Changes saved successfully! The changes might take longer than 5min to appear."
      );
      //@ts-ignore
      dispatch(
        //@ts-ignore
        logActivity(`Changed pages behind campaign(s) 
       <ul>${
         //@ts-ignore
         payload.campaigns.map(
           (obj) => `
        <li>
          <a href="https://panel.ouisys.com/campaigns/browse?xcid=${obj.xcid}" target="_blank" rel="noopener noreferrer">${obj.xcid}
            </li>
          </a>`
         )
       }
       </ul>
       <br/>
       from : <a href="https://panel.ouisys.com/pages/published?${queryString.stringify(
         cleanObj(payload.previousObj)
       )}"  target="_blank" rel="noopener noreferrer">${JSON.stringify(
          cleanObj(payload.previousObj)
        )}</a>
       <br/>
       to: <a href="https://panel.ouisys.com/pages/published?${queryString.stringify(
         cleanObj({
           page: payload.page,
           country: payload.country,
           scenario: payload.scenario,
           strategy: payload.strategy,
           scenarios_config: payload.scenarios_config,
         })
       )}">${JSON.stringify(
          cleanObj({
            page: payload.page,
            country: payload.country,
            scenario: payload.scenario,
            strategy: payload.strategy,
            scenarios_config: payload.scenarios_config,
          })
        )}</a>`)
      );
    }
  };
}

export function addCloaking(payload: {
  server_instructions: {
    type: CloakType;
    value: {
      page_info?: {
        page?: string;
        country?: string;
        scenario?: string | null;
        strategy?: string | null;
        scenarios_config?: string | null;
      };
      redirect_url?: string;
      record_impression: boolean;
      clientSideRedirection?: boolean | undefined;
      cloak_user_agent: string;
      cloak_referer: boolean;
      cloak_dns_hostnames: boolean;
      target_ip_range_name: string;
    };
  };
  campaigns?: Array<ICampaign>;
}) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    try {
      dispatch(toggleLoading(true));
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;

      const result = await axios({
        method: "post",
        url: `/api/v1/add-cloaking-config`,
        data: payload,
      });
      if (result && result.status === 200) {
        const data = result.data;
        dispatch({
          type: ADD_CLOAKING_CONFIG_SUCCESS,
          payload: data,
        });
        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success(
          "Changes saved successfully! The changes might take longer than 5min to appear."
        );
        //@ts-ignore
        dispatch(
          //@ts-ignore
          logActivity(`Added cloaking to campaign(s) 
            <ul>${
              //@ts-ignore
              payload.campaigns.map(
                (obj) => `
              <li>
                <a href="https://panel.ouisys.com/campaigns/browse?xcid=${obj.xcid}" target="_blank" rel="noopener noreferrer">${obj.xcid}
                  </li>
                </a>`
              )
            }
            </ul>
            <br/>`)
        );
      }
    } catch (err) {
      dispatch(toggleLoading(true));
      message.error(JSON.stringify(err));
    }
  };
}

export function removeCloaking(payload: { campaigns?: Array<ICampaign> }) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    try {
      dispatch(toggleLoading(true));
      const { pageNumber, pageSize, search } = store().campaigns.campaigns;

      const result = await axios({
        method: "post",
        url: `/api/v1/remove-cloaking-config`,
        data: payload,
      });
      if (result && result.status === 200) {
        const data = result.data;
        dispatch({
          type: REMOVE_CLOAKING_CONFIG_SUCCESS,
          payload: data,
        });
        //@ts-ignore
        dispatch(
          //@ts-ignore
          getCampaigns({
            pageNumber,
            pageSize,
            search,
          })
        );
        //dispatch(toggleEditModal(false));
        dispatch(toggleLoading(false));
        message.success(
          "Changes saved successfully! The changes might take longer than 5min to appear."
        );
        //@ts-ignore
        dispatch(
          //@ts-ignore
          logActivity(`Removed cloaking to campaign(s) 
            <ul>${
              //@ts-ignore
              payload.campaigns.map(
                (obj) => `
              <li>
                <a href="https://panel.ouisys.com/campaigns/browse?xcid=${obj.xcid}" target="_blank" rel="noopener noreferrer">${obj.xcid}
                  </li>
                </a>`
              )
            }
            </ul>
            <br/>`)
        );
      }
    } catch (err) {
      dispatch(toggleLoading(true));
      message.error(JSON.stringify(err));
    }
  };
}

export function takeScreenShot(payload: {
  page: string;
  country: string;
  scenario: string;
  strategy: string;
  scenarios_config: string;
}) {
  const { page, country, scenario, strategy, scenarios_config } = payload;

  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    store: () => IApplicationState
  ) => {
    const result = await axios.get(
      `/api/v1/take_screenshot?page=${page}&country=${country}&scenario=${scenario}&strategy=${strategy}&scenarios_config=${scenarios_config}&environment=production`
    );
    if (result.status === 200) {
      const data = result.data;
      dispatch({
        type: "ujujujuj",
        payload: data,
      });
    }
  };
}

//TOGGLE_LOGIN
export function toggleLoading(payload: boolean) {
  return {
    type: CAMPAIGNS_LOADING,
    payload,
  };
}

export function resetCampaigns() {
  return {
    type: RESET_CAMPAIGNS,
  };
}

function handleCampaigns(state: ICampaignsState, action: Action) {
  return update(state, {
    campaigns: {
      $set: action.payload,
    },
  });
}

function handleToggleEditModal(state: ICampaignsState, action: Action) {
  return update(state, {
    showEditModal: {
      $set: action.payload,
    },
  });
}

//LOGIN_LOADING
function handleLoading(state: ICampaignsState, action: Action) {
  return update(state, {
    isLoading: {
      $set: action.payload,
    },
  });
}

function handleChangeCampaignsSuccess(state: ICampaignsState, action: Action) {
  return update(state, {
    isChangeConfigSuccess: {
      $set: action.payload,
    },
  });
}

function handleResetCampaigns(state: ICampaignsState, action: Action) {
  return update(state, {
    isChangeConfigSuccess: {
      $set: false,
    },
    isLoading: {
      $set: false,
    },
    showEditModal: {
      $set: false,
    },
  });
}

function handleGetPublishedPages(state: ICampaignsState, action: Action) {
  return update(state, {
    publishedPages: {
      $set: action.payload,
    },
  });
}

function handleGetSources(state: ICampaignsState, action: Action) {
  return update(state, {
    sources: {
      $set: action.payload,
    },
  });
}

function handleFindCampaigns(state: ICampaignsState, action: Action) {
  return update(state, {
    searchedCamapigns: {
      $set: action.payload,
    },
  });
}

function handleCreateMultipleCampaigns(state: ICampaignsState, action: Action) {
  return update(state, {
    createdCampaigns: {
      $set: action.payload,
    },
  });
}

function handleGetCampaignStats(state: ICampaignsState, action: Action) {
  const { key, value } = action.payload;
  return update(state, {
    campaignStats: {
      [key]: {
        $set: value,
      },
    },
  });
}

function handleGetCampaignStatsCallMade(
  state: ICampaignsState,
  action: Action
) {
  const { key, value } = action.payload;
  return update(state, {
    campaignStatsCalled: {
      [key]: {
        $set: value,
      },
    },
  });
}

function handleCreateSafeLink(state: ICampaignsState, action: Action) {
  return update(state, {
    safeLink: {
      $set: action.payload,
    },
  });
}

function handleResetSafeLink(state: ICampaignsState, action: Action) {
  return update(state, {
    safeLink: {
      $set: null,
    },
  });
}

function handleCloneCampaign(state: ICampaignsState, action: Action) {
  return update(state, {
    clonedCampaign: {
      $set: action.payload,
    },
  });
}

const ACTION_HANDLERS = {
  GET_CAMPAIGNS: handleCampaigns,
  TOGGLE_EDIT_CAMPAIGN_MODAL: handleToggleEditModal,
  CAMPAIGNS_LOADING: handleLoading,
  CHANGE_CAMPAIGNS_CONFIG_SUCCESS: handleChangeCampaignsSuccess,
  RESET_CAMPAIGNS: handleResetCampaigns,
  GET_PUBLISHED_PAGES_CAMPAIGNS: handleGetPublishedPages,
  GET_SOURCES: handleGetSources,
  FIND_CAMPAIGNS: handleFindCampaigns,
  CREATE_MULTIPLE_CAMPAIGNS: handleCreateMultipleCampaigns,
  GET_CAMPAIGN_STATS: handleGetCampaignStats,
  GET_CAMPAIGN_STATS_CALL_MADE: handleGetCampaignStatsCallMade,
  CREATE_SAFE_LINK: handleCreateSafeLink,
  RESET_SAFE_LINK: handleResetSafeLink,
  CLONE_CAMPAIGN: handleCloneCampaign,
};

const initialState = {
  showEditModal: false,
  isLoading: false,
  isChangeConfigSuccess: false,
  campaignStats: {},
  campaignStatsCalled: {},
};

export default function CampaignsReducer(state = initialState, action: Action) {
  const handler = ACTION_HANDLERS[action.type];
  //@ts-ignore
  return handler ? handler(state, action) : state;
}
