import axios, { AxiosError } from "axios";
import { message, Alert } from "antd";
import * as R from "ramda";

function errorResponseHandler(error: any) {
  // check for errorHandle config
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  if (error.response) {
    if (error.response.data.liveEnv) {
      // message.error(error.response.data.error);
      return alert(`
                ${error.response.data.error}\n

                LIVE PAGE CONGIGURATION \n
                ${JSON.stringify(error.response.data.liveEnv, null, 2)} \n
                PAGE IN GITHUB CONGIGURATION \n
                ${JSON.stringify(error.response.data.githubEnv, null, 2)}
            `);
    }
    if (error.response.data && error.response.data.error) {
      message.error(error.response.data.error);
    } else {
      // message.error(error.response.data.error);
    }
  }
}

export default errorResponseHandler;
