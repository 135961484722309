
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Routes from "./routes";

import { IApplicationState } from "./store/reducers";

import './styles/base.scss';

import { establishCurrentUser } from "./routes/Login/module";
import axios from "axios";
import errorResponseHandler from "./utils/axiosErrorHandler";
import { Button } from "antd";
import { withRouter, RouteComponentProps } from "react-router";
import { getCountriesOperators } from "./routes/Configs/module";

axios.defaults.headers.common = {
  authorization: localStorage.getItem('token')
};
// apply interceptor on response
axios.interceptors.response.use(
  response => response,
  errorResponseHandler
);

const App: React.FC<RouteComponentProps> = ({history}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state:IApplicationState) => state.login.isAuthenticated);
  const [isOpen, setOpen] = useState<boolean>(false)
  const toggle = () => setOpen(!isOpen);

  useEffect(()=>{
    dispatch(getCountriesOperators())
  },[])



  useEffect(()=>{
    dispatch(establishCurrentUser());

    const currentVersion = "3";
    const panelVersion = window.localStorage.getItem("panel_version");

    if(!panelVersion){
      window.localStorage.removeItem("campaign_cols")
      window.localStorage.removeItem("campaign_cols_v3")
      window.localStorage.removeItem("create_campaign_cols")
      window.localStorage.removeItem("published_campaign_cols")
      window.localStorage.removeItem("hot_pages_cols")
      window.localStorage.setItem("panel_version", currentVersion)
    }

    if(!!panelVersion && parseInt(panelVersion) < 2){
      //Update
      window.localStorage.setItem("panel_version", currentVersion)
    }
  },[])

  return (
    <>
      <Routes/>
    </>
  );
}

export default withRouter(App);
